import axios from "axios";
import { toast } from "react-toastify";
import React from "react";
import http from "http";
import https from "https";
let API_SERVER_PREFIX = process.env.REACT_APP_SERVER_DOMAIN;

if (process.env.NODE_ENV === "production") {
  if (process.env.REACT_APP_SERVER_ENVIRONMENT === "PROD") {
    API_SERVER_PREFIX = process.env.REACT_APP_SERVER_DOMAIN;
  } else {
    API_SERVER_PREFIX =
      process.env.REACT_APP_SERVER_DOMAIN + process.env.REACT_APP_SERVER_ENVIRONMENT + "/";
  }
}
const API_SERVER_PREFIX_WITH_VERSION = API_SERVER_PREFIX + "1.0/";
const HEADERS = {
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
  withCredentials: true,
  // headers: {
  // Accept: "application/json",
  // "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest"
  // }
};
const getDownloadHeaders = () => {
  return {
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    withCredentials: true,
    // headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    // },
    responseType: "blob"
  };
};

const handleError = error => {
  const { response, message } = error;
  if (response) {
    const { status, statusText, data } = response;
    switch (status) {
      case 401:
        // do something when you're unauthenticated
        return handleInvalidSession({ statusText });

      case 403:
        // do something when you're unauthorized to access a resource
        return handleInvalidSession({ statusText, noRedirectToPreviousPage: true });

      case 500:
        toast.error(
          <div>
            <div>
              <b>Message:</b> {statusText}
            </div>
            <div>
              <b>Status code:</b> {status}
            </div>
            <div> {data.message || data.error || JSON.stringify(data)} </div>
          </div>
        );
        return statusText;

      default:
        toast.error(status);
      // handle normal errors with some alert or whatever
    }
  }
  return message; // I like to get my error message back
};

//creating an instance that can be used with predefined config

const axiousInstance = axios.create({
  baseURL: API_SERVER_PREFIX_WITH_VERSION,
  headers: HEADERS
});

// HTTP GET Request - Returns Resolved or Rejected Promise
const getRequest = path =>
  new Promise((resolve, reject) =>
    axiousInstance
      .get(path)
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      })
  );

// HTTP POST Request - Returns Resolved or Rejected Promise
const postFileRequest = (path, data) =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_PREFIX_WITH_VERSION}${path}`, data, {
        ...Headers,
        responseType: "blob"
      })
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      })
  );
// HTTP PATCH Request - Returns Resolved or Rejected Promise
const putRequest = (path, data) =>
  new Promise((resolve, reject) =>
    axiousInstance
      .put(path, data)
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      })
  );
// HTTP POST Request - Returns Resolved or Rejected Promise
const postRequest = (path, data) =>
  new Promise((resolve, reject) =>
    axiousInstance
      .post(path, data)
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      })
  );
// HTTP DELETE Request - Returns Resolved or Rejected Promise
const delRequest = path =>
  new Promise((resolve, reject) =>
    axiousInstance
      .delete(path)
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      })
  );

// HTTP POST Request - Returns Resolved or Rejected Promise
const postDownloadRequest = (path, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_SERVER_PREFIX_WITH_VERSION}${path}`, data, getDownloadHeaders())
      .then(response => {
        resolve({ ...response, body: response.data });
      })
      .catch(error => {
        reject(handleError(error));
      });
  });
};

function handleInvalidSession({ statusText, noRedirectToPreviousPage }) {
  toast.error("Invalid session, Please login to continue");
  sessionStorage.clear();

  if (window && window.location && window.location.assign)
    if (noRedirectToPreviousPage) window.location.assign("/");
    else
      window.location.assign(
        "/?go2=" + encodeURIComponent(window.location.pathname + window.location.search)
      );
  return statusText;
}

export {
  API_SERVER_PREFIX,
  getRequest,
  postRequest,
  putRequest,
  delRequest,
  postFileRequest,
  postDownloadRequest
};
