import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Input, Jumbotron, Label, Row } from "reactstrap";
import { getPegsReservation } from "../../api/pegs";
import "./pegs.scss";

const SEARCH_PHRASE = "?htConfCode=";
export default function Pegs({ location }) {
  let { search } = location;

  const searchFor = (search && search.replace(SEARCH_PHRASE, "")) || "";
  const [searchParamm, setSearchParamm] = useState(searchFor);
  const [isLoading, setIsLoading] = useState(true);
  const [resData, setResData] = useState({});
  const [errorMessage, setErrorMessage] = useState("Reservation not found!");

  useEffect(() => {
    if (!!searchFor) {
      getPegsReservation(searchFor)
        .then(resp => {
          setIsLoading(false);
          let { body } = resp;

          if (body) {
            if (body.reservationResponse) setResData(body.reservationResponse);
            else setErrorMessage(body.error);
          }
        })
        .catch(err => {
          setIsLoading(false);
          toast.error(err);
        });
    } else {
      setIsLoading(false);
      toast.info("Please provide conf code!");
    }
  }, [searchFor]);

  function handleFetch(code) {
    window.location.assign(`/pegs/${SEARCH_PHRASE}${code}`);
  }

  const { propertyCode, roomStayList } = resData;

  return (
    <div>
      <ToastContainer />
      {isLoading ? (
        <center>Please Wait...</center>
      ) : (
        <div className="pegs-container">
          <Jumbotron fluid>
            <Container fluid>
              <div className="pegs-form">
                <label>
                  <b>HT confirmation Code</b>
                </label>
                <Input onChange={e => setSearchParamm(e.target.value)} value={searchParamm} />
                <button onClick={() => handleFetch(searchParamm)}>Search</button>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {propertyCode && (
                  <label className="property-label">
                    <b>Property Code:</b> {propertyCode}
                  </label>
                )}
              </div>
              {(roomStayList &&
                roomStayList.map((room, index) => (
                  <RoomDetails key={index} room={room}></RoomDetails>
                ))) || (
                <Card className="no-data-card">
                  <CardBody>
                    <center className="property-label" style={{ color: "red" }}>
                      {errorMessage}
                    </center>
                  </CardBody>
                </Card>
              )}
            </Container>
          </Jumbotron>
        </div>
      )}
    </div>
  );
}

function RoomDetails({ room }) {
  let keys = Object.keys(room).filter(field => typeof room[field] === "boolean" || !!room[field]);
  return (
    <Card>
      <CardBody>
        <Row xs="1" sm="2" md="4">
          {keys &&
            keys.map((field, index) => (
              <Col key={index}>
                <Label>
                  {startCase(field)}:{" "}
                  <b>
                    {typeof room[field] !== "boolean"
                      ? startCase(field) === "Cancel Date Time" && room[field].startsWith("1970")
                        ? "--"
                        : room[field]
                      : !!room[field]
                      ? "True"
                      : "False"}
                  </b>
                </Label>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  );
}
