import { getUserProfile } from "../api";
import rules from "../rbac-rules";
import { login, logout } from "../api/login";
import SecureSS from "../utils/secure-xs/secureSS";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

export const DO_AUTHENTICATE = "DO_AUTHENTICATE";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_LOGIN = "SET_IS_LOGIN";
export const LOGOUT = "LOGOUT";

/*
 * action creators
 */

export const doAuthenticate = () => ({ type: DO_AUTHENTICATE });

export const setLoginError = errorMessage => {
  return { type: SET_LOGIN_ERROR, errorMessage };
};
export const setIsLoggingIn = isLoggingIn => {
  return { type: SET_IS_LOGIN, isLoggingIn };
};
export const handleLogout = () => {
  return { type: LOGOUT };
};
export const asyncGetUserProfile = (callback, dispatch) => {
  dispatch(setIsLoggingIn(true));
  getUserProfile().then(
    success => {
      dispatch(setIsLoggingIn(false));
      const userProfileData = success.body;
      if (userProfileData) {
        ReactGA.initialize("UA-177866127-1");
        ReactGA.set({ userId: userProfileData.username });
        const { role } = userProfileData;
        if (!role) {
          return toast.error("User has no role!");
        }
        // SecureSS.set("clientId", success.body.clientId);
        // SecureSS.set("clientType", success.body.clientType);
        SecureSS.set("userProfile", userProfileData);
        callback({
          authenticated: true,
          user: {
            role,
            ...userProfileData
          },
          homePage: rules[role].homePage
        });
      }
    },
    failure => {
      dispatch(setIsLoggingIn(false));
      console.log(failure);
    }
  );
};

export function asyncAdminConsoleLogin(user, dispatch, callback) {
  dispatch(setLoginError(""));
  dispatch(setIsLoggingIn(true));
  const newUser = { username: user.j_username, password: user.j_password };
  return login(newUser)
    .then(resp => {
      if (resp.body) {
        SecureSS.set("jwtToken", resp.body.accessToken);
        asyncGetUserProfile(callback, dispatch);
        return;
      }
      dispatch(setIsLoggingIn(false));
      dispatch(setLoginError("Unable to login"));
    })
    .catch(error => {
      dispatch(setIsLoggingIn(false));
      console.error(error);
      dispatch(setLoginError("Unable to login"));
    });
}
export function asyncClientMgmtLogout(dispatch, callback) {
  dispatch(setIsLoggingIn(true));
  return logout()
    .then(resp => {
      dispatch(setIsLoggingIn(false));
      sessionStorage.clear();
      callback();
    })
    .catch(error => {
      dispatch(setIsLoggingIn(false));
      console.error(error);
      sessionStorage.clear();
      callback();
    });
}
