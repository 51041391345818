import React from "react";

import "./login.scss";
import { Input, Form, FormGroup, Button, Spinner } from "reactstrap";

const ChangePasswordContainer = ({ isLoggingIn, handleChangePassword, success, hideExtraFields }) => {
  return (
    <div>
      <Form className="login-container-padding" onSubmit={e => handleChangePassword(e)}>
        <FormGroup>
          <Input
            hidden={hideExtraFields}
            readOnly={isLoggingIn}
            type="text"
            id="username"
            className="fadeIn second"
            name="username"
            placeholder="User Name"
          />
        </FormGroup>
        <FormGroup>
          <Input
            hidden={hideExtraFields}
            readOnly={isLoggingIn}
            type="text"
            id="activationKey"
            className="fadeIn second"
            name="activationKey"
            placeholder="Activation Key"
          />
        </FormGroup>
        <FormGroup>
          <Input
            readOnly={isLoggingIn}
            type="password"
            id="password1"
            className="fadeIn second"
            name="password1"
            placeholder="New Passwoord"
          />
        </FormGroup>
        <FormGroup>
          <Input
            readOnly={isLoggingIn}
            type="password"
            id="password2"
            className="fadeIn second"
            name="password2"
            placeholder="Confirm Password"
          />
        </FormGroup>

        <Button type="submit" className="fadeIn fourth" color="primary" disabled={isLoggingIn || success}>
          {isLoggingIn ? <Spinner size="sm" /> : "Change password"}
        </Button>
      </Form>

      <div id="formFooter">
        <a className="underlineHover" href="/">
          Login
        </a>
      </div>
    </div>
  );
};

export default ChangePasswordContainer;
