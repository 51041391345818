import React from "react";

import { Route, Redirect } from "react-router-dom";
import Can from "./Can";

const PrivateRoute = ({ component: PassedComponent, perform, path, exact, ...rest }) => (
  <Route
    path={path}
    {...rest}
    exact
    render={props => (
      <Can
        perform={perform}
        yes={() => <PassedComponent {...props} />}
        no={() => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location + props.search }
            }}
          />
        )}
      />
    )}
  />
);

export default PrivateRoute;
