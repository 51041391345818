import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { AuthConsumer } from "../../authContext";

import "./login.scss";
import Logo from "../../assets/static/ht-logo.png";
import { Alert } from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import Login from "./Login";
import ResetPasswordEmail from "./ResetPasswordEmail";
import ChangePasswordContainer from "./ChangePasswordContainer";
import { changePassword, resetPassword } from "../../api/login";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export default class LoginContainer extends PureComponent {
  state = { hideExtraFields: false };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const params = {};
    for (var pair of searchParams.entries()) {
      params[pair[0]] = pair[1];
    }

    if (params && params.resetKey && params.email)
      this.setState({ ...params, hideExtraFields: true, containerName: CHANGE_PASSWORD });
  }

  handleOnSubmit = (e, callback) => {
    e.preventDefault();
    const j_username = e.target.elements.j_username.value;
    const j_password = e.target.elements.j_password.value;
    this.props.authenticateUser({ j_username, j_password }, callback);
  };

  setContainerName = page => {
    this.setState({
      containerName: page
    });
    this.props.setLoginError("");
  };

  toastifyErrors = field => {
    toast.error(field + " cannot be empty!");
  };

  handleChangePassword = e => {
    e.preventDefault();
    const { email, resetKey } = this.state;
    const username = email || e.target.elements.username.value;
    const password = e.target.elements.password1.value;
    const confirmPassword = e.target.elements.password2.value;
    const activationKey = resetKey || e.target.elements.activationKey.value;

    if (!username || !password || !activationKey) {
      if (!username) this.toastifyErrors("User name");
      if (!password) this.toastifyErrors("Password");
      if (!activationKey) this.toastifyErrors("Activation key");

      return;
    }
    if (confirmPassword !== password) {
      toast.error("Passwords should be same");
      return;
    }

    this.setState({ isLoading: true });
    changePassword({ username, password, activationKey }).then(
      success => {
        this.setState({ isLoading: false, success: true });
        toast.success(success.body);
      },
      failure => {
        this.setState({ isLoading: false });
        toast.error("Failed");
      }
    );
  };

  handleResetPassword = e => {
    e.preventDefault();

    const username = e.target.elements.email.value;

    if (!username) {
      this.toastifyErrors("User name");
      return;
    }

    resetPassword({ username, source: window.location.origin }).then(
      success => {
        this.setState({ isLoading: false, success: true });
        toast.success(success.body);
      },
      failure => {
        this.setState({ isLoading: false });
        toast.error("Failed");
      }
    );
  };

  renderContent = (handleAuthentication, isLoggingIn, success) => {
    const { isLoading, hideExtraFields } = this.state;

    if (this.state.containerName === FORGOT_PASSWORD) {
      return (
        <ResetPasswordEmail
          showChangePassword={() => this.setContainerName(CHANGE_PASSWORD)}
          showLogin={() => this.setContainerName("")}
          handleOnSubmit={this.handleResetPassword}
        />
      );
    }
    if (this.state.containerName === CHANGE_PASSWORD) {
      return (
        <ChangePasswordContainer
          isLoggingIn={isLoading}
          handleChangePassword={this.handleChangePassword}
          success={success}
          hideExtraFields={hideExtraFields}
        />
      );
    }

    return (
      <Login
        handleAuthentication={handleAuthentication}
        isLoggingIn={isLoggingIn}
        handleOnSubmit={this.handleOnSubmit}
        showResetPassword={() => this.setContainerName(FORGOT_PASSWORD)}
      />
    );
  };

  render() {
    let { search, state } = this.props.location;
    const getRedirectPage = homePage => {
      search = decodeURIComponent(search);
      if (search) {
        if (search.startsWith("?go2=")) {
          search = search.replace("?go2=", "");
        }
      } else {
        search = homePage;
      }
      return (state && state.from && state.from.pathname) || search;
      // return search;
    };

    const { errorMessage, isLoggingIn } = this.props;

    return (
      <AuthConsumer>
        {({ authenticated, handleAuthentication, homePage }) =>
          authenticated ? (
            <Redirect to={getRedirectPage(homePage)} />
          ) : (
            <div>
              <div className="wrapper fadeInDown">
                <ToastContainer
                  position="top-right"
                  autoClose={10000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
                <div id="formContent">
                  <div className="fadeIn first">
                    <img src={Logo} id="icon" alt="User Icon" />
                  </div>
                  <br />
                  {errorMessage && (
                    <Alert color="danger" className="login-container-padding m-d-20">
                      {errorMessage}
                    </Alert>
                  )}
                  <br />
                  {this.renderContent(handleAuthentication, isLoggingIn)}
                </div>
              </div>
            </div>
          )
        }
      </AuthConsumer>
    );
  }
}
