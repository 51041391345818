import {
  getFormattedDateInEDTWithFormat,
  getFormattedTime,
  getRoundedToTwoDecimals
} from "../../constants";
import { isASuperUserLoggedIn } from "../../utils/secure-xs/secureSS";

export function downloadXML(xml, title) {
  const blob = new Blob([new TextEncoder().encode(xml)], {
    type: "text/plain"
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  const fileName = title || "xml.xml";
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function getElement(name, rootElement) {
  if (!rootElement) throw new Error("Null root element");
  if (rootElement.name === name) return rootElement;

  // Base case
  const { elements } = rootElement;
  if (!elements) return null;

  // Breadth First Search
  for (let i = 0; i < elements.length; i++) {
    const childElement = elements[i];
    if (childElement.name === name) return childElement;
  }

  // Check child elements recursively
  for (let j = 0; j < elements.length; j++) {
    const childElement = elements[j];
    const match = getElement(name, childElement);
    if (match) {
      return match;
    }
  }
}

export function processReservationReportForRendering(reports, isMultiService) {
  if (!isMultiService) {
    let newReports = [];
    reports.forEach(element => {
      //nullify the room details after creating a reservation for each room
      const { roomDetails } = element;
      if (roomDetails && roomDetails.length) {
        const rooms = roomDetails.map(room => ({
          ...element,
          ...room,
          roomDetails: null,
          reservationConfirmationNumber: element.confirmationNumber
        }));
        newReports = newReports.concat(rooms);
      } else {
        newReports.push({
          ...element,
          roomDetails: null,
          reservationConfirmationNumber: element.confirmationNumber
        });
      }
    });
    reports = newReports;
  }
  return reports.map(prepareReportToSupportMultiService);
}
function prepareReportToSupportMultiService(reservation, rowIndex) {
  const isSuperUser = isASuperUserLoggedIn();
  const id = "parent-" + rowIndex;
  if (isSuperUser) {
    if (reservation.serviceDetails) {
      reservation.children = reservation.serviceDetails.map((ser, i) => {
        let children;
        if (ser.roomDetails) {
          children = ser.roomDetails.map((roo, childIndex) => ({
            ...ser,
            ...roo,
            parentId: id + "-ser-" + i,
            index: rowIndex + 1 + "." + (i + 1) + "." + (childIndex + 1),
            type: "room",
            id: id + "-ser-" + i + "-roo-" + childIndex,
            children: null
          }));
        }
        return {
          ...ser,
          parentId: id,
          id: id + "-ser-" + i,
          type: "service",
          index: rowIndex + 1 + "." + (i + 1),
          children
        };
      });
    } else if (reservation.roomDetails && reservation.roomDetails.length > 1) {
      reservation.children = reservation.roomDetails.map((roo, i) => ({
        ...reservation,
        ...roo,
        parentId: id,
        index: rowIndex + 1 + "." + (i + 1),
        type: "room",
        id: id + "-roo-" + i,
        children: null
      }));
    }
  }
  return { ...reservation, index: rowIndex + 1, id };
}

export const ariAuditReservationInfoColumns = [
  { name: "Conf #", dataField: "confirmationNumber" },
  { name: "Demand Client", dataField: "companyCode" },
  { name: "Property Name", dataField: "propertyName" },
  { name: "Rate Plan", dataField: "rateCode" },
  { name: "CRS Hotel Confirmation #", dataField: "sourceConfirmationNumber" },
  { name: "PMS Conf #", dataField: "pmsConfirmationNumber" },
  { name: "Client Confirmation Number", dataField: "otaConfirmationCode" },
  { name: "Status", dataField: "cancelled", formatter: value => (value ? "Canceled" : "Active") },
  {
    name: "Guest Name",
    dataField: "guestName",
    formatter: (value, row) => `${row.firstName} ${row.lastName}`
  },
  {
    name: "No Of Guests",
    dataField: "noOfGuests",
    formatter: (cell, row) =>
      row.noOfAdults + "A" + (row.noOfChildren > 0 ? " / " + row.noOfChildren + "C" : "")
  },
  { name: "Room Code", dataField: "roomCode" },
  { name: "Room Type", dataField: "room" },
  { name: "LOS", dataField: "los" },
  {
    name: "Sale Date",
    dataField: "bookingDateDuplicate",
    formatter: (cell, row) => getFormattedDateInEDTWithFormat(row["bookingDateDuplicate"])
  },
  { name: "Sale Time", dataField: "bookingDateDuplicate", formatter: getFormattedTime },
  { name: "Arrival", dataField: "arrival" },
  { name: "Departure", dataField: "departure" },
  { name: "Lead Time", dataField: "leadTime" },
  { name: "Comments", dataField: "comment" },
  { name: "Special Requests", dataField: "specialRequest" }
];

export const ariAuditPricingInfoColumns = [
  { name: "Payable Cost", dataField: "payableNetPrice" },
  { name: "Payable Tax", dataField: "payableTax" },
  { name: "Payable Total", dataField: "payableTotal" },
  { name: "HT Commission%", dataField: "htCommissionPercent" },
  { name: "Invoice Price", dataField: "receivableNetPrice" },
  { name: "Invoice Tax", dataField: "receivableTax" },
  { name: "Invoice Total", dataField: "receivableGrossPrice" },
  { name: "Client Sale Price", dataField: "clientSalePrice" },
  { name: "Client Taxes", dataField: "taxes" },
  { name: "Client Gross Price", dataField: "grossPrice" },
  { name: "Extra Adult Amount", dataField: "extraAdultAmount" },
  { name: "Max Adult Occupancy", dataField: "maxAdultOccupancy", formatter: cell => cell },
  { name: "Extra Child Amount", dataField: "extraChildAmount" },
  { name: "Max Child Occupancy", dataField: "maxChildOccupancy", formatter: cell => cell }
].map(col => ({
  ...col,
  formatter: col.formatter ? col.formatter : value => getRoundedToTwoDecimals({ value })
}));
