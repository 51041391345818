import React from "react";

import "./login.scss";
import { Input, Form, FormGroup, Button, Spinner } from "reactstrap";

const ResetPasswordEmail = ({ handleAuthentication, isLoggingIn, handleOnSubmit, showChangePassword, showLogin }) => {
  return (
    <div>
      <Form className="login-container-padding" onSubmit={e => handleOnSubmit(e, handleAuthentication)}>
        <FormGroup>
          <Input readOnly={isLoggingIn} type="text" id="email" className="fadeIn second" name="email" placeholder="Email Id" />
        </FormGroup>

        <Button type="submit" className="fadeIn fourth" color="primary" disabled={isLoggingIn}>
          {isLoggingIn ? <Spinner size="sm" /> : "Reset Password"}
        </Button>
      </Form>

      <div id="formFooter">
        <Button className="underlineHover" onClick={showLogin} color="link">
          Login
        </Button>
        <Button className="underlineHover" onClick={showChangePassword} color="link">
          Have reset code?
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordEmail;
