import {
  searchProperty,
  intgAuditARI,
  intgAuditARIHBRes,
  getProperty,
  getPropertyImages,
  getAllClients,
  addAClient,
  getSourceCommission,
  updateSourceCommission,
  addSourceCommission,
  getAllSuppliers,
  blacklistProperty,
  getBlacklistedProperties,
  reListProperty,
  getDirectCommissions,
  addOrUpdateRateplans,
  getClientById,
  getClient,
  updateClientById,
  updateClient,
  getTaxes,
  updateTaxes,
  deleteTaxes,
  updatePropertyAmenities,
  deleteAmenity,
  getReportDashboard,
  getAriReportDashboard,
  getResTotals,
  searchReservationReport,
  getInvoicingActionItems
} from "../api";
import { toast } from "react-toastify";
import { processReservationReportForRendering } from "../components/ReservationReport/utils";
import { isASuperUserLoggedIn } from "../utils/secure-xs/secureSS";

export const SET_PROPERTY_NAME_TO_SEARCH = "SET_PROPERTY_NAME_TO_SEARCH";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const UPDATE_SELECTED_PROPERTY = "UPDATE_SELECTED_PROPERTY";
export const UPDATE_SEARCHED_PROPERTIES = "UPDATE_SEARCHED_PROPERTIES";
export const SET_SEARCH_PROPERTY_LOADING = "SET_SEARCH_PROPERTY_LOADING";
export const SET_RESERVATION_REPORT_LOADING = "SET_RESERVATION_REPORT_LOADING";
export const SET_SHOW_RESERVATION_REPORT = "SET_SHOW_RESERVATION_REPORT";
export const SET_RESERVATION_REPORT = "SET_RESERVATION_REPORT";
export const SET_INTG_AUDIT_ARI_CONTENT = "SET_INTG_AUDIT_ARI_CONTENT";
export const SET_HB_RES_INTG_AUDIT_ARI_CONTENT = "SET_HB_RES_INTG_AUDIT_ARI_CONTENT";
export const SET_INTG_AUDIT_ARI_LOADING = "SET_INTG_AUDIT_ARI_LOADING";
export const TOGGLE_INTG_AUDIT_ARI_POPUP = "TOGGLE_INTG_AUDIT_ARI_POPUP";
export const TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP = "TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP";
export const SET_INTG_AUDIT_ARI_REQ_PARAMS = "SET_INTG_AUDIT_ARI_REQ_PARAMS";
export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY";
export const SET_PROPERTY_AMENITIES = "SET_PROPERTY_AMENITIES";
export const SET_PROPERTY_IMAGES = "SET_PROPERTY_IMAGES";
export const UPDATE_REPORT_COLUMNS = "UPDATE_REPORT_COLUMNS";
export const SET_PROPERTY_TAXES = "SET_PROPERTY_TAXES";
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const SET_A_CLIENT = "SET_A_CLIENT";
export const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
export const SET_SOURCE_COMMISSION = "SET_SOURCE_COMMISSION";
export const SET_RESERVATION_REPORT_REQ_PARAMS = "SET_RESERVATION_REPORT_REQ_PARAMS";
export const TOGGLE_BLACKLISTED_PROP_LIST = "TOGGLE_BLACKLISTED_PROP_LIST";
export const SET_BLACKLISTED_PROPS = "SET_BLACKLISTED_PROPS";
export const SET_BLACKLISTED_PROPS_LOADING = "SET_BLACKLISTED_PROPS_LOADING";
export const SET_DIRECT_COMMISSIONS = "SET_DIRECT_COMMISSIONS";
export const UPDATED_COLUMNS_HIDDEN = "UPDATED_COLUMNS_HIDDEN";
export const COLUMNS_FILTER_SELECTED = "COLUMNS_FILTER_SELECTED";
export const SET_REPORT_DASHBOARD_LOADING = "SET_REPORT_DASHBOARD_LOADING";
export const REPORT_DASHBOARD = "REPORT_DASHBOARD";
export const SET_ARI_REPORT_DASHBOARD_LOADING = "SET_ARI_REPORT_DASHBOARD_LOADING";
export const ARI_REPORT_DASHBOARD = "ARI_REPORT_DASHBOARD";
export const SET_RES_TOTALS_LOADING = "SET_RES_TOTALS_LOADING";
export const RES_TOTAL = "RES_TOTAL";
export const SET_ACTIONABLE_INVOICES = "SET_ACTIONABLE_INVOICES";

//action creators
export const updateReportColumns = reservationReportColumns => ({
  type: UPDATE_REPORT_COLUMNS,
  reservationReportColumns
});
export const setSearchPropertyBy = payload => ({
  type: SET_PROPERTY_NAME_TO_SEARCH,
  payload
});
export const updateSelectedProperty = property => ({
  type: UPDATE_SELECTED_PROPERTY,
  property
});
export const setSearchPropertyLoading = isSearchPropertyLoading => ({
  type: SET_SEARCH_PROPERTY_LOADING,
  isSearchPropertyLoading
});
export const setSearchReservationReportLoading = isReservationReportLoading => ({
  type: SET_RESERVATION_REPORT_LOADING,
  isReservationReportLoading
});
export const setReservationReportRequestParams = reservationReportReqParams => ({
  type: SET_RESERVATION_REPORT_REQ_PARAMS,
  reservationReportReqParams
});
export const setClientDetails = clientDetails => ({
  type: SET_CLIENT_DETAILS,
  clientDetails
});

export const toggleReservationReport = showReservationReport => ({
  type: SET_SHOW_RESERVATION_REPORT,
  showReservationReport
});
export const updateSearchedProperties = properties => ({
  type: UPDATE_SEARCHED_PROPERTIES,
  properties
});
export const setSelectedProperty = selectedProperty => ({
  type: SET_SELECTED_PROPERTY,
  selectedProperty
});
export const setPropertyTaxes = propertyTaxes => ({
  type: SET_PROPERTY_TAXES,
  propertyTaxes
});
export const setPropertyImages = propertyImages => ({
  type: SET_PROPERTY_IMAGES,
  propertyImages
});
export const setPropertyAmenities = propertyAmenities => ({
  type: SET_PROPERTY_AMENITIES,
  propertyAmenities
});
export const setReservationReport = reservationReports => ({
  type: SET_RESERVATION_REPORT,
  reservationReports
});
export const setIntgAuditARIContent = intgAuditARIContent => ({
  type: SET_INTG_AUDIT_ARI_CONTENT,
  intgAuditARIContent
});
export const setHBResIntgAuditARIContent = hbResintgAuditARIContent => ({
  type: SET_HB_RES_INTG_AUDIT_ARI_CONTENT,
  hbResintgAuditARIContent
});
export const toggleIntgAuditARIPopup = showIntgAuditARIPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_POPUP,
  showIntgAuditARIPopup
});
export const toggleIntgAuditARIHBResPopup = showIntgAuditARIHBResPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP,
  showIntgAuditARIHBResPopup
});
export const setIntgAuditARILoading = isIntgAuditARILoading => ({
  type: SET_INTG_AUDIT_ARI_LOADING,
  isIntgAuditARILoading
});

export const setIntgAuditARIReqParams = intgAuditARIReqParams => ({
  type: SET_INTG_AUDIT_ARI_REQ_PARAMS,
  intgAuditARIReqParams
});
export const setAllClients = allClients => ({
  type: SET_ALL_CLIENTS,
  allClients
});
export const setAClient = clientToEdit => ({
  type: SET_A_CLIENT,
  clientToEdit
});
export const setAllSuppliers = allSuppliers => ({
  type: SET_ALL_SUPPLIERS,
  allSuppliers
});

export const setSourceCommission = sourceCommission => ({
  type: SET_SOURCE_COMMISSION,
  sourceCommission
});
export const toggleBlacklistedPropertyList = showBlacklistedProperties => ({
  type: TOGGLE_BLACKLISTED_PROP_LIST,
  showBlacklistedProperties
});
export const setBlackListedProperties = blacklistedProperties => ({
  type: SET_BLACKLISTED_PROPS,
  blacklistedProperties
});
export const setBlackListedPropsLoading = blacklistedPropertiesLoading => ({
  type: SET_BLACKLISTED_PROPS_LOADING,
  blacklistedPropertiesLoading
});
export const setDirectCommissions = directCommissions => ({
  type: SET_DIRECT_COMMISSIONS,
  directCommissions
});
export const updateColumnsHidden = columnsHidden => ({
  type: UPDATED_COLUMNS_HIDDEN,
  columnsHidden
});
export const updateColumnsFilterSelected = columnsFilterSelected => ({
  type: COLUMNS_FILTER_SELECTED,
  columnsFilterSelected
});
export const setIsReportDashboardLoading = isReportDashboardLoading => {
  return { type: SET_REPORT_DASHBOARD_LOADING, isReportDashboardLoading };
};
export const setReportDashboard = reportDashboard => {
  return { type: REPORT_DASHBOARD, reportDashboard };
};
export const setIsAriReportDashboardLoading = isAriReportDashboardLoading => {
  return { type: SET_ARI_REPORT_DASHBOARD_LOADING, isAriReportDashboardLoading };
};
export const setAriReportDashboard = ariReportDashboard => {
  return { type: ARI_REPORT_DASHBOARD, ariReportDashboard };
};
export const setIsResTotalsLoading = isResTotalsLoading => {
  return { type: SET_RES_TOTALS_LOADING, isResTotalsLoading };
};
export const setResTotals = resTotals => {
  return { type: RES_TOTAL, resTotals };
};
export const setActionableInvoices = actionableInvoices => {
  return { type: SET_ACTIONABLE_INVOICES, actionableInvoices };
};

// async actions
export const asyncGetProperty = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getProperty(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setSelectedProperty(success.body));
      }
      asyncGetPropertyAmenities(propId, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetInvoicingActionItems = dispatch => {
  dispatch(setSearchPropertyLoading(true));

  getInvoicingActionItems()
    .then(resp => {
      dispatch(setActionableInvoices(resp.body));
    })
    .catch(err => console.log(err));
};
export const asyncGetPropertyTaxes = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyTaxes(success.body.propertyList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdatePropertyTaxes = (propName, callback, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  updateTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      callback();
      asyncGetPropertyTaxes({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncDeletePropertyTaxes = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  deleteTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      asyncGetPropertyTaxes({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdatePropertyAmenities = (propName, callback, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  updatePropertyAmenities(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      callback();
      asyncGetPropertyAmenities({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncDeletePropertyAmenities = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  deleteAmenity(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      asyncGetPropertyAmenities({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetPropertyAmenities = (propId, dispatch) => {
  // dispatch(setSearchPropertyLoading(true));
  // getPropertyAmenities(propId).then(
  //   success => {
  //     dispatch(setSearchPropertyLoading(false));
  //     if (success.body) {
  //       dispatch(setPropertyAmenities(success.body.propertyAmenitiesDTOList));
  //     }
  //     asyncGetPropertyImages(propId, dispatch);
  //   },
  //   failure => {
  //     dispatch(setSearchPropertyLoading(false));
  //     console.log(failure);
  //   }
  // );
  const amenitiesResponse = {
    propertyAmenitiesDTOList: [
      {
        propertyAmenitiesId: 110,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62593,
          amenityType: 1,
          amenityName: "24-hour front desk ",
          expediaAmenityId: 2633185
        },
        amenityDisplayOrder: 1,
        amenityName: "24-hour front desk ",
        amenityImageUrl:
          "https://d1d0bgvqdg71ke.cloudfront.net/images/amenities/icon-reception.png",
        isActive: 1
      },
      {
        propertyAmenitiesId: 124,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62610,
          amenityType: 1,
          amenityName: "Local Expert Concierge ",
          expediaAmenityId: 10422742
        },
        amenityDisplayOrder: 2,
        amenityName: "Local Expert Concierge ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 125,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62611,
          amenityType: 1,
          amenityName: "Luggage storage ",
          expediaAmenityId: 7104124
        },
        amenityDisplayOrder: 3,
        amenityName: "Luggage storage ",
        amenityImageUrl: "https://d1d0bgvqdg71ke.cloudfront.net/images/amenities/icon-luggage.png",
        isActive: 1
      },
      {
        propertyAmenitiesId: 126,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62613,
          amenityType: 1,
          amenityName: "Multilingual staff ",
          expediaAmenityId: 2633183
        },
        amenityDisplayOrder: 4,
        amenityName: "Multilingual staff ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 128,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62616,
          amenityType: 1,
          amenityName: "Porter/bellhop ",
          expediaAmenityId: 2633186
        },
        amenityDisplayOrder: 5,
        amenityName: "Porter/bellhop ",
        amenityImageUrl:
          "https://d1d0bgvqdg71ke.cloudfront.net/images/amenities/icon-bellman-cart.png",
        isActive: 1
      },
      {
        propertyAmenitiesId: 129,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62669,
          amenityType: 1,
          amenityName: "Restaurant",
          expediaAmenityId: 2665369
        },
        amenityDisplayOrder: 6,
        amenityName: "Restaurant",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 130,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62618,
          amenityType: 1,
          amenityName: "Safe-deposit box at front desk ",
          expediaAmenityId: 2633182
        },
        amenityDisplayOrder: 7,
        amenityName: "Safe-deposit box at front desk ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 131,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62619,
          amenityType: 1,
          amenityName: "Smoke-free property ",
          expediaAmenityId: 10106019
        },
        amenityDisplayOrder: 8,
        amenityName: "Smoke-free property ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 133,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62622,
          amenityType: 1,
          amenityName: "Tours/ticket assistance ",
          expediaAmenityId: 3758789
        },
        amenityDisplayOrder: 9,
        amenityName: "Tours/ticket assistance ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 134,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62672,
          amenityType: 1,
          amenityName: "Valet parking (surcharge)",
          expediaAmenityId: 6551855
        },
        amenityDisplayOrder: 10,
        amenityName: "Valet parking (surcharge)",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 135,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62708,
          amenityType: 1,
          amenityName: "Wired (high-speed) Internet access - surcharge",
          expediaAmenityId: 6408826
        },
        amenityDisplayOrder: 11,
        amenityName: "Wired (high-speed) Internet access - surcharge",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 123,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62607,
          amenityType: 1,
          amenityName: "In-room accessibility ",
          expediaAmenityId: 8079979
        },
        amenityDisplayOrder: 12,
        amenityName: "In-room accessibility ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 122,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62653,
          amenityType: 1,
          amenityName: "Fitness facilities ",
          expediaAmenityId: 9307597
        },
        amenityDisplayOrder: 13,
        amenityName: "Fitness facilities ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 111,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62700,
          amenityType: 1,
          amenityName: "Accessible path of travel ",
          expediaAmenityId: 8224358
        },
        amenityDisplayOrder: 14,
        amenityName: "Accessible path of travel ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 112,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62701,
          amenityType: 1,
          amenityName: "Airport transportation (surcharge) ",
          expediaAmenityId: 3459532
        },
        amenityDisplayOrder: 15,
        amenityName: "Airport transportation (surcharge) ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 113,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62702,
          amenityType: 1,
          amenityName: "Area shuttle (surcharge) ",
          expediaAmenityId: 7124011
        },
        amenityDisplayOrder: 16,
        amenityName: "Area shuttle (surcharge) ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 114,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62664,
          amenityType: 1,
          amenityName: "Bar/lounge",
          expediaAmenityId: 729697
        },
        amenityDisplayOrder: 17,
        amenityName: "Bar/lounge",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 115,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62597,
          amenityType: 1,
          amenityName: "Breakfast available (surcharge) ",
          expediaAmenityId: 10029155
        },
        amenityDisplayOrder: 18,
        amenityName: "Breakfast available (surcharge) ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 117,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62704,
          amenityType: 1,
          amenityName: "Computer station",
          expediaAmenityId: 10977660
        },
        amenityDisplayOrder: 19,
        amenityName: "Computer station",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 118,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62705,
          amenityType: 1,
          amenityName: "Concierge services",
          expediaAmenityId: 927190
        },
        amenityDisplayOrder: 20,
        amenityName: "Concierge services",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 119,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62603,
          amenityType: 1,
          amenityName: "Dry cleaning/laundry service ",
          expediaAmenityId: 3894584
        },
        amenityDisplayOrder: 21,
        amenityName: "Dry cleaning/laundry service ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 120,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62604,
          amenityType: 1,
          amenityName: "Elevator/lift",
          expediaAmenityId: 774785
        },
        amenityDisplayOrder: 22,
        amenityName: "Elevator/lift",
        amenityImageUrl: "https://d1d0bgvqdg71ke.cloudfront.net/images/amenities/icon-elevator.png",
        isActive: 1
      },
      {
        propertyAmenitiesId: 121,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62606,
          amenityType: 1,
          amenityName: "Express check-out ",
          expediaAmenityId: 2633187
        },
        amenityDisplayOrder: 23,
        amenityName: "Express check-out ",
        amenityImageUrl: null,
        isActive: 1
      },
      {
        propertyAmenitiesId: 136,
        propertyId: 277,
        amenitiesMasterDTO: {
          amenitiesMasterId: 62674,
          amenityType: 1,
          amenityName: "Wireless Internet access - surcharge",
          expediaAmenityId: 6732610
        },
        amenityDisplayOrder: 24,
        amenityName: "Wireless Internet access - surcharge",
        amenityImageUrl: null,
        isActive: 1
      }
    ]
  };
  dispatch(setPropertyAmenities(amenitiesResponse.propertyAmenitiesDTOList));
  const propertyImages = {
    propertyImagesList: [
      {
        idpropertyImages: 5694,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/3631d397_z.jpg",
        caption: "Reception",
        imageOrder: 1
      },
      {
        idpropertyImages: 5699,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/17613092_z.jpg",
        caption: "Hotel Lounge",
        imageOrder: 2
      },
      {
        idpropertyImages: 5700,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/4cb0344f_z.jpg",
        caption: "Hotel Lounge",
        imageOrder: 3
      },
      {
        idpropertyImages: 5692,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/dbfce10c_z.jpg",
        caption: "Guestroom",
        imageOrder: 4
      },
      {
        idpropertyImages: 5696,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/082be42c_z.jpg",
        caption: "Guestroom",
        imageOrder: 5
      },
      {
        idpropertyImages: 5697,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/7eaeed86_z.jpg",
        caption: "Guestroom",
        imageOrder: 6
      },
      {
        idpropertyImages: 5698,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/85bbdebb_z.jpg",
        caption: "Guestroom",
        imageOrder: 7
      },
      {
        idpropertyImages: 5689,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/419a57b5_z.jpg",
        caption: "Bathroom",
        imageOrder: 8
      },
      {
        idpropertyImages: 5690,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/e8a5c557_z.jpg",
        caption: "Bathroom Sink",
        imageOrder: 9
      },
      {
        idpropertyImages: 5703,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/90cc94ae_z.jpg",
        caption: "Bathroom",
        imageOrder: 10
      },
      {
        idpropertyImages: 5704,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/35ba0a60_z.jpg",
        caption: "Guestroom",
        imageOrder: 11
      },
      {
        idpropertyImages: 5705,
        propertyId: 273,
        expediaHotelId: 109277,
        imageUrl1: "//media.expedia.com/hotels/1000000/10000/4200/4164/675037ae_z.jpg",
        caption: "Guestroom",
        imageOrder: 12
      }
    ]
  };
  dispatch(setPropertyImages(propertyImages.propertyImagesList));
};
export const asyncGetPropertyImages = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getPropertyImages(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyImages(success.body.propertyImagesList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetReportDashboard = (params, dispatch) => {
  dispatch(setIsReportDashboardLoading(true));
  getReportDashboard().then(
    success => {
      dispatch(setIsReportDashboardLoading(false));
      if (success.body) {
        dispatch(setReportDashboard(success.body));
      }
    },
    failure => {
      dispatch(setIsReportDashboardLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAriReportDashboard = (params, dispatch) => {
  dispatch(setIsAriReportDashboardLoading(true));
  getAriReportDashboard().then(
    success => {
      dispatch(setIsAriReportDashboardLoading(false));
      if (success.body) {
        dispatch(setAriReportDashboard(success.body));
      }
    },
    failure => {
      dispatch(setIsAriReportDashboardLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetResTotals = (params, dispatch) => {
  dispatch(setIsResTotalsLoading(true));
  getResTotals(params).then(
    success => {
      dispatch(setIsResTotalsLoading(false));
      if (success.body) {
        dispatch(setResTotals(success.body));
      }
    },
    failure => {
      dispatch(setIsResTotalsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncSearchProperty = (propertyName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  return searchProperty(propertyName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(updateSearchedProperties(success.body));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      dispatch(updateSearchedProperties([]));
      // intimateLoginToast();
      toast.error("Error while fetching the property");
    }
  );
};
export const asyncSearchReservationReport = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  dispatch(setReservationReport([]));
  return searchReservationReport(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      dispatch(toggleReservationReport(true));
      if (success.body) {
        const reports = success.body;

        dispatch(
          setReservationReport(processReservationReportForRendering(reports, params.isMultiService))
        );
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      dispatch(toggleReservationReport(false));
      console.log(failure);
      if (failure.name === "Error") {
        // intimateLoginToast();
        toast.error("Error while fetching the reservation");
      }
    }
  );
};

export const asyncIntgAuditARI = (params, dispatch) => {
  dispatch(setIntgAuditARIReqParams(params));
  dispatch(setIntgAuditARILoading(true));
  if (params.clientCode === "Hotelbeds" && params.operation === "RESERVATIONNOTIFRQ_OUT") {
    return intgAuditARIHBRes(
      params.htConfirmationCode,
      new Date(params.saleDate).getTime() + 19800000
    ).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIHBResPopup(true));
        if (success.body) {
          dispatch(setHBResIntgAuditARIContent(success.body.reservationXmlList));
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIHBResPopup(false));
        console.log(failure);
      }
    );
  } else {
    return intgAuditARI(params).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIPopup(true));
        if (success.body) {
          dispatch(setIntgAuditARIContent(success.body));
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIPopup(false));
        console.log(failure);
      }
    );
  }
};

export const asyncGetAllClients = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllClients(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setAllClients(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAClient = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  const api = isASuperUserLoggedIn() ? getClientById : getClient;
  return api(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setClientDetails({ ...success.body, fromServer: true }));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdateClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  const api = isASuperUserLoggedIn() ? updateClientById : updateClient;
  return api(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully updated client");
        if (isASuperUserLoggedIn()) {
          history.push("/home/clients/list");
        }
      } else {
        toast.error("Error updating the client");
      }
    },
    failure => {
      toast.error("Failed to update a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncAddClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addAClient(params).then(
    success => {
      if (success.body.success) {
        toast.success("Successfully added a Client");
        history.push("/home/clients/list");
      } else {
        toast.error("Error creating the client.\n" + success.errorMessage);
      }
      dispatch(setSearchReservationReportLoading(false));
      // asyncGetAllClients(null, dispatch);
    },
    failure => {
      toast.error("Error creating the client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
      }
    },
    failure => {
      toast.error("Failed to add a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdateSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return updateSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAllSuppliers = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllSuppliers(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
        dispatch(setAllSuppliers(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncBlackListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return blacklistProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Blacklisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncReListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  reListProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Whitelisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetBlacklistedProperties = (propId, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getBlacklistedProperties(propId).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setBlackListedProperties(success.body));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetDirectCommissions = (userName, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getDirectCommissions(userName).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setDirectCommissions(success.body.propertyRateplans));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddOrUpdateRateplans = ({ userName, propertyId, rateplan }, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  addOrUpdateRateplans(userName, propertyId, rateplan).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        asyncGetDirectCommissions(userName, dispatch);
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
