import { connect } from "react-redux";
import PureComponent from "./component";
import { asyncAdminConsoleLogin, setLoginError } from "../../actions/login";
const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    errorMessage: state.login.errorMessage,
    isLoggingIn: state.login.isLoggingIn
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  authenticateUser: (user, cb) => asyncAdminConsoleLogin(user, dispatch, cb),
  setLoginError: error => dispatch(setLoginError(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(PureComponent);
