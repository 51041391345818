import React from "react";
import CustomisedLink from "./CustomisedLink";
import Can from "../Can";

const SidebarBlock = ({ title, linksMap, access }) => {
  return (
    <div>
      <div className="sb-nav-title">{title}</div>
      <div className="sb-nav-container-inner">
        {linksMap.map(({ to, label, activeOnlyWhenExact, linkAccess }, index) => (
          <Can
            key={index}
            perform={linkAccess || access}
            yes={() => <CustomisedLink key={index} to={to} label={label} activeOnlyWhenExact={activeOnlyWhenExact} />}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarBlock;
