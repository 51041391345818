import React from "react";

export default function Footer() {
  return (
    <footer className="ta-c p-20 lh-0 fsz-sm c-grey-600">
      {/* include "bdT" in className if borderTop is needed */}
      <span>Copyright HotelTrader© 2021. All rights reserved.</span>
    </footer>
  );
}
