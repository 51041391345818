import React, { useEffect } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";

import "react-texty/styles.css";
import "react-base-table/styles.css";

import Home from "./components/Home";
import Login from "./components/Login";
import Pegs from "./components/Pegs";

import rootReducer from "./reducers";
import Auth from "./components/Auth";
import { logPageView } from "./utils/AnalyticsTracker";
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const App = () => (
  <Provider store={store}>
    <Auth>
      <Router>
        <RouterChildrenSubComponent />
      </Router>
    </Auth>
  </Provider>
);

function RouterChildrenSubComponent() {
  const history = useHistory();
  useEffect(() => {
    logPageView(history);
  }, [history]);

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/pegs" component={Pegs} />
      <Route path="/home" component={Home} />
    </Switch>
  );
}
export default App;
