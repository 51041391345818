import React from "react";
import { Badge } from "reactstrap";

export const sidebarContent = ({
  actionableInvoicesCount,
  metadataNotificationsCount,
  property
}) => [
  {
    access: "dayrateConfig",
    title: "Dayrate/Availability",
    linksMap: [
      {
        to: "/home/demand-client-management",
        label: "Demand Client Management",
        linkAccess: "noAccess",
        activeOnlyWhenExact: true
      },
      {
        to: "/home/tourico-hotel-creation",
        label: "Tourico Hotel Creation",
        linkAccess: "noAccess"
      },
      {
        to: "/home/intg-ari-monitoring",
        label: "Intg ARI Monitoring",
        linkAccess: "noAccess"
      },
      {
        to: "/home/advanced-ari-search",
        label: "Advanced ARI Search",
        linkAccess: "advancedARISearch"
      },
      {
        to: "/home/rate-availability-audit-clients",
        label: "Rate/Availability Audit for Clients",
        linkAccess: "rateAvailabilityAudit"
      },
      {
        to: "/home/quick-price-search",
        label: "Quick Price Search",
        linkAccess: "quickPriceSearch"
      },
      {
        to: "/home/demand-client-management/source-commission-config",
        label: "Source Commission Configuration",
        linkAccess: "noAccess"
      },
      {
        to: "/home/day-rate-calendar",
        label: "DayRate Calendar Lookup",
        linkAccess: "dayRateCalendarLookup"
      },
      {
        to: "/home/rate-calendar",
        label: "Rate Calendar",
        linkAccess: "old"
      },
      {
        to: "/home/hotelbeds-mapping",
        label: "Hotelbeds Mapping",
        linkAccess: "noAccess"
      }
    ]
  },
  {
    access: "propertyManagement",
    title: "Property Management",
    linksMap: [
      {
        to: "/home/select-property",
        label: "Property",
        linkAccess: "noAccess",
        activeOnlyWhenExact: true
      },
      {
        to: "/home/property-master",
        label: "Property" + (property && property.propertyCode ? " - " + property.propertyCode : "")
      },
      {
        to: "/home/property-taxes",
        label: "Property Taxes",
        linkAccess: "noAccess"
      },
      {
        to: "/home/add-property",
        label: "Add Property",
        linkAccess: "noAccess"
      },
      {
        to: "/home/import-property",
        label: "Import Property",
        linkAccess: "noAccess"
      },
      {
        to: "/home/rateplan-management",
        label: "Rateplan Management",
        linkAccess: "noAccess"
      },
      {
        to: "/home/rate-plans",
        label: "Rateplans"
      },
      {
        to: "/home/hotelbeds-prop-mapping",
        label: "Hotelbeds property mapping",
        linkAccess: "noAccess"
      },
      {
        to: "/home/rooms",
        label: "Rooms"
      },
      {
        to: "/home/policies",
        label: "Policies"
      },
      {
        to: "/home/taxes",
        label: "Taxes"
      },
      {
        to: "/home/metadata-management",
        label: "Metadata Management"
      }
      // {
      //   to: "/home/metadata-management-new",
      //   label: "Metadata Management New"
      // }
    ]
  },
  {
    access: "reports",
    title: "Reports",
    linksMap: [
      {
        to: "/home/reservation-report",
        label: "Reservation Report"
      },
      {
        linkAccess: "reportsFailed",
        to: "/home/failed-reservations",
        label: "Failed Reservations"
      }
    ]
  },
  {
    access: "clientManagement",
    title: "Client Management",
    linksMap: [
      {
        to: "/home/clients/list",
        label: "List Clients",
        linkAccess: "listClients"
      },
      {
        to: "/home/clients/add-client",
        label: "Quick Create Client",
        linkAccess: "addClient"
      },
      {
        to: "/home/clients/client-info",
        label: "View Client Info",
        linkAccess: "viewClientInfo"
      }
    ]
  },
  {
    access: "stratificationManagement",
    title: "Stratification Management",
    linksMap: [
      {
        to: "/home/groups",
        label: "List Groups"
      }
    ]
  },

  {
    access: "invoiceModule",
    title: "Invoice Management",
    linksMap: [
      {
        to: "/home/invoices/list",
        label: (
          <div>
            Invoicing{" "}
            <Badge color="danger" pill>
              {!!actionableInvoicesCount && actionableInvoicesCount}
            </Badge>
          </div>
        ),
        // remove below linkAccess to allow in client too
        linkAccess: "invoiceModule"
      },
      {
        to: "/home/invoices/credits",
        label: "Credits",
        // remove below linkAccess to allow in client too
        linkAccess: "invoiceModule"
      },
      {
        to: "/home/invoices/payments",
        label: "Payments",
        // remove below linkAccess to allow in client too
        linkAccess: "invoiceModule"
      },
      {
        to: "/home/invoices/aging",
        label: "Aging",
        // remove below linkAccess to allow in client too
        linkAccess: "invoiceModuleSuperAdmin"
      },
      {
        to: "/home/invoices/client-schedule",
        label: "Invoice Scheduling",
        linkAccess: "invoiceModuleSuperAdmin"
      },
      {
        to: "/home/invoices/client-payment-allocation",
        label: "Client Payment Allocation Screen",
        linkAccess: "invoiceModuleClientAdmin"
      },
      {
        to: "/home/invoices/supplier-invoice",
        label: "Supplier Invoice Upload",
        linkAccess: "invoiceModuleSuperAdmin"
      }
    ]
  },

  {
    access: "suOnlyControls",
    title: "SU Only Controls",
    linksMap: [
      {
        to: "/home/ari-delivery-summary",
        label: "ARI Delivery Summary"
      },
      {
        linkAccess: "noAccess",
        to: "/home/customize-homepage",
        label: "Customize homepage"
      },
      {
        linkAccess: "noAccess",
        to: "/home/customize-promotions",
        label: "Customize Promotions"
      },
      {
        linkAccess: "noAccess",
        to: "/home/property-configuration",
        label: "Property Configuration"
      },
      {
        linkAccess: "noAccess",
        to: "/home/cache-live-status",
        label: "Cache Live Status"
      },
      {
        linkAccess: "noAccess",
        to: "/home/cancel-reservation",
        label: "Cancel Reservation"
      },
      // {
      //   linkAccess: "suOnlyControls",
      //   to: "/home/global-rateplan-management",
      //   label: "Global Rateplan Management"
      // },
      {
        to: "/home/rooms-rates-audit",
        label: "Roomtypes/Rateplans Audit"
      },
      {
        linkAccess: "old",
        to: "/home/audit-ari-dashboard",
        label: "Audit ARI Dashboard"
      },
      {
        linkAccess: "suOnlyControls",
        to: "/home/dpr",
        label: "DPR"
      },
      {
        to: "/home/metadata-pivot",
        label: "Metadata Pivot",
        linkAccess: "NoAccess"
      },
      {
        to: "/home/ari-audit",
        label: "ARI Audit",
        linkAccess: "noAccess"
      },
      {
        to: "/home/rates-inventory",
        label: "Rates/Inventory Pivot",
        linkAccess: "noAccess"
      },
      {
        to: "/home/ari-full-push",
        label: "Rates/ARI Full Push"
      },
      {
        to: "/home/sync-diss",
        label: "Sync DISS"
      },
      {
        to: "/home/ari-lookup",
        label: "ARI XML Search"
      },
      {
        to: "/home/mmm",
        label: "Margin Management Module"
      },
      {
        to: "/home/add-property",
        label: "Add New Pegasus Property"
      },
      {
        to: "/home/push-clients-queue-status",
        label: "Push Clients Queue Status"
      },
      {
        to: "/home/metadata-notifications",
        label: (
          <div>
            Metadata Notifications{" "}
            <Badge color="danger" pill>
              {!!metadataNotificationsCount ? metadataNotificationsCount : 0}
            </Badge>
          </div>
        )
      }
    ]
  },
  {
    access: "noAccess", // validateReservations",
    title: "Validate Reservations",
    linksMap: [{ to: "/home/show-reservation-list", label: "Show List" }]
  },
  {
    access: "noAccess", // updateGuestInformation",
    title: "Update Guest Information",
    linksMap: [{ to: "/home/show-guest-list", label: "Show List" }]
  },
  {
    access: "usersCompaniesManagement",
    title: "Users / Companies",
    linksMap: [
      {
        to: "/home/users",
        label: "List Users",
        activeOnlyWhenExact: true
      },
      {
        to: "/home/users/add-user",
        label: "Add User"
      },
      {
        to: "/home/list-companies",
        label: "List Companies",
        linkAccess: "noAccess"
      },
      {
        to: "/home/add-company",
        label: "Add Company",
        linkAccess: "noAccess"
      },
      {
        to: "http://kibana.hoteltrader.com/",
        label: "Kibana Dashboard"
      }
    ]
  }
];
