import React from "react";
import { AuthConsumer } from "../../authContext";

import "./login.scss";
import { Input, Form, FormGroup, Button, Spinner } from "reactstrap";

const Login = ({ handleAuthentication, isLoggingIn, handleOnSubmit, showResetPassword }) => {
  return (
    <div>
      <Form className="login-container-padding" onSubmit={e => handleOnSubmit(e, handleAuthentication)} method="post">
        <FormGroup>
          <Input
            readOnly={isLoggingIn}
            type="text"
            id="login"
            className="fadeIn second"
            name="j_username"
            placeholder="User name"
          />
        </FormGroup>
        <FormGroup>
          <Input
            readOnly={isLoggingIn}
            type="password"
            id="password"
            className="fadeIn third"
            name="j_password"
            placeholder="Password"
            required
          />
        </FormGroup>
        <AuthConsumer>
          {({ initiateLogin }) => (
            <Button
              type="submit"
              className="fadeIn fourth"
              color="primary"
              disabled={isLoggingIn}
              onClick={initiateLogin}
              required
            >
              {isLoggingIn ? <Spinner size="sm" /> : "Log In"}
            </Button>
          )}
        </AuthConsumer>
      </Form>

      <div id="formFooter">
        <Button className="underlineHover" onClick={showResetPassword} color="link">
          Forgot Password?
        </Button>
      </div>
    </div>
  );
};

export default Login;
