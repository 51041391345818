import React from "react";
import ClearCache from "react-clear-cache";
import PropertyAutoComplete from "../SearchProperty/PropertyAutoComplete";

import {
  Navbar,
  NavLink,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand
} from "reactstrap";

import { useState } from "react";
import CustomPopUpWindow from "../Home/CustomPopUpWindow";
import { searchProperty } from "../../api";
import { Col, FormGroup, Row } from "reactstrap";

export default function Header({
  toggleIsCollapsed,
  isCollapsedClass,
  logout,
  property,
  updateSelectedProperty
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [showPropertySelector, setShowPropertySelector] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isFetchingProperties, setIsFetchingProperties] = useState(false);
  const [propertyToSearch, setPropertyToSearch] = useState("");
  const [selectedProperty, setSelectedProperty] = useState();

  const toggle = () => setIsOpen(!isOpen);

  function asyncSearchProperty(propertyName) {
    searchProperty(propertyName).then(
      success => {
        setIsFetchingProperties(false);
        if (success.body) {
          setProperties(success.body);
        }
      },
      failure => {
        setIsFetchingProperties(false);
        setProperties([]);
      }
    );
  }

  return (
    <div className="header navbar">
      <div className="header-container">
        <ul className="nav-left">
          <li>
            <NavLink
              id="sidebar-toggle"
              className="sidebar-toggle"
              color="link"
              onClick={() => toggleIsCollapsed(!isCollapsedClass)}
            >
              <i className="ti-menu" />
            </NavLink>
          </li>
        </ul>

        <Navbar color="" light expand="md" className="nav-right pt-3">
          <NavbarBrand>
            <NavItem>
              <CacheRefresher />
            </NavItem>
          </NavbarBrand>

          <NavbarToggler onClick={toggle}>x</NavbarToggler>

          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {property && (
                <NavItem>
                  <Button
                    onClick={() => setShowPropertySelector(true)}
                    color="link"
                    className="mR-10"
                  >
                    {property.propertyName}
                  </Button>
                </NavItem>
              )}
              <Button color="link" className="text-decoration-none" onClick={logout}>
                <i className="fa fa-power-off mR-5" />
                <span>Logout</span>
              </Button>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      <CustomPopUpWindow
        isOpen={showPropertySelector}
        header="Property selector"
        toggle={() => {
          setShowPropertySelector(false);
          setSelectedProperty();
        }}
        extraButtons={
          <Button
            color="primary"
            disabled={!selectedProperty}
            onClick={() => {
              updateSelectedProperty(selectedProperty);
              setSelectedProperty();
              setShowPropertySelector(false);
            }}
          >
            Update
          </Button>
        }
        body={
          <Row>
            <Col>
              <FormGroup>
                <PropertyAutoComplete
                  properties={properties}
                  value={propertyToSearch}
                  isSearchPropertyLoading={isFetchingProperties}
                  onSuggestionSelected={setSelectedProperty}
                  setSearchPropertyBy={value => {
                    setPropertyToSearch(value);
                  }}
                  asyncSearchProperty={asyncSearchProperty}
                  updateSearchedProperties={setProperties}
                />
              </FormGroup>
            </Col>
          </Row>
        }
      />
    </div>
  );
}

function CacheRefresher() {
  return (
    <ClearCache duration={process.env.NODE_ENV === "development" ? 10000000000 : 10000}>
      {({ isLatestVersion, emptyCacheStorage }) => (
        <div>
          {!isLatestVersion && (
            <Button
              color="link"
              className="text-danger"
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              <i className="fa fa-bell"></i> Click here to access the new version of the app.
            </Button>
          )}
        </div>
      )}
    </ClearCache>
  );
}
