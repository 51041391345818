import React from "react";
import Logo from "../../assets/static/ht-logo.png";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import { NavLink } from "reactstrap";
import SidebarBlock from "./SidebarBlock";
import Can from "../Can";
import { sidebarContent } from "../../constants/sidebarMenu";

const Sidebar = ({
  toggleIsCollapsed,
  actionableInvoicesCount,
  metadataNotificationsCount,
  property
}) => {
  return (
    <div className="sidebar">
      <div className="sidebar-inner">
        <div className="sidebar-logo">
          <div className="peers ai-c fxw-nw">
            <div className="peer peer-greed">
              <Link to="/home">
                <div className="peers ai-c fxw-nw">
                  <div className="peer">
                    <div className="logo">
                      <img src={Logo} alt="HotelTrader Logo" />
                    </div>
                  </div>
                  <div className="peer peer-greed" />
                </div>
              </Link>
            </div>
            <div className="peer">
              <div className="mobile-toggle sidebar-toggle">
                <NavLink className="td-n sidebar-collapse-back">
                  <i className="ti-arrow-circle-left" onClick={() => toggleIsCollapsed(false)} />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="sb-nav-container sidebar-menu" onClick={() => toggleIsCollapsed(false)}>
          {sidebarContent({ actionableInvoicesCount, metadataNotificationsCount, property }).map(
            ({ access, title, linksMap }, index) => (
              <Can
                key={index}
                perform={access}
                yes={() => <SidebarBlock title={title} linksMap={linksMap} access={access} />}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
