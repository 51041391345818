import React from "react";

const WARNING_ALERT_MINUTES = 119;
const LOGOUT_MINUTES = 120;
export default function SessionTimerHOC(ComposedClass) {
  class AutoLogout extends React.Component {
    state = {
      warningTime: WARNING_ALERT_MINUTES * 60 * 1000,
      signoutTime: LOGOUT_MINUTES * 60 * 1000
    };

    componentDidMount() {
      this.events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      // this.warnTimeout = setTimeout(this.warn, this.state.warningTime); // should be this.warn
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      window.alert("Current session will be terminated in 5 mins.");
    };

    logout = () => {
      // Send a logout request to the API
      window.alert("Session timed out.");
      this.destroy();
    };

    destroy = () => {
      //clear the session
      this.clearTimeoutFunc();
      sessionStorage.clear();
      window.location.assign("/");
    };

    render() {
      return <ComposedClass {...this.props} />;
    }
  }
  return AutoLogout;
}
