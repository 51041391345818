import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export default function CustomPopUpWindow(props) {
  let {
    toggle,
    isOpen,
    header,
    body,
    extraButtons,
    hideFooter,
    className,
    closeButtonText,
    hideHeader
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop className={className || ""}>
      {!hideHeader && (
        <ModalHeader toggle={toggle} className="">
          {header}
        </ModalHeader>
      )}
      {body && <ModalBody>{body}</ModalBody>}
      {!hideFooter && (
        <ModalFooter>
          {!!extraButtons && extraButtons}
          <Button onClick={toggle}>{closeButtonText || "Close"}</Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
