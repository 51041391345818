import { connect } from "react-redux";
import PureComponent from "./component";

import { handleLogout, asyncClientMgmtLogout } from "../../actions/login";
import {
  updateSelectedProperty,
  asyncGetProperty,
  asyncGetInvoicingActionItems
} from "../../actions/home";

const mapStateToProps = (state, ownProps) => {
  const { home } = state;
  const { actionableInvoices, selectedProperty } = home;
  return {
    isLoggedIn: state.login.isLoggedIn,
    property: selectedProperty || {},
    actionableInvoicesCount: actionableInvoices && actionableInvoices.actionableInvoicesCount,
    actionableMetadataCount: actionableInvoices && actionableInvoices.actionableMetadataCount
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(handleLogout()),
  asyncGetInvoicingActionItems: () => asyncGetInvoicingActionItems(dispatch),
  updateSelectedProperty: p => dispatch(updateSelectedProperty(p)),
  asyncGetProperty: v => asyncGetProperty(v, dispatch),
  asyncClientMgmtLogout: callback => asyncClientMgmtLogout(dispatch, callback)
});

export default connect(mapStateToProps, mapDispatchToProps)(PureComponent);
