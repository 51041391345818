import SecureXS from "../secure-xs";
import { USER_ROLES } from "../../constants";

const SecureSS = new SecureXS();

export function isASuperUserLoggedIn() {
  return SecureSS.get("userProfile").role === USER_ROLES.SUPER_USER;
}

export function isAPotentialUserLoggedIn() {
  return SecureSS.get("userProfile").role === USER_ROLES.POTENTIAL_USER;
}

export function getLoggedUserClientId() {
  return SecureSS.get("userProfile").clientId;
}

export function isAPullClientLoggedIn() {
  if (SecureSS.get("userProfile").clientType === "CLIENT_PULL") {
    return true;
  }
  return false;
}

export function isAPushClientLoggedIn() {
  if (SecureSS.get("userProfile").clientType === "CLIENT_PUSH") {
    return true;
  }
  return false;
}

export function isAPullOrPushClientLoggedIn() {
  // The assumption here is there will be only 2 types of clients
  // CLIENT_PUSH & CLIENT_PULL
  const clientType = SecureSS.get("userProfile").clientType;
  if (clientType === "CLIENT_PUSH" || clientType === "CLIENT_PULL") {
    return true;
  }
  return false;
}

export function getLoggedUserUsername() {
  return SecureSS.get("userProfile").username;
}

export function isAClientAdminUserLoggedIn() {
  return SecureSS.get("userProfile").role === USER_ROLES.CLIENT_ADMIN;
}

export function isAClientFinanceUserLoggedIn() {
  return SecureSS.get("userProfile").role === USER_ROLES.CLIENT_FINANCE;
}

export function getLoggedUserRole() {
  return SecureSS.get("userProfile").role;
}

export default SecureSS;
