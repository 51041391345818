import React, { PureComponent, Suspense } from "react";
import { Route } from "react-router-dom";
import SessionTimerHOC from "./SessionTimerHOC";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import PrivateRoute from "../PrivateRoute";

import { ToastContainer, Slide } from "react-toastify";
import { AuthConsumer } from "../../authContext";
import { Redirect, Switch } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-texty/styles.css";
import "react-base-table/styles.css";
import "./component.scss";

import Loader from "./Loader";

/* add webpackChunkName, it is a must, will be used to name the chunks after the build */
const DPR = React.lazy(() => import("../DPR" /* webpackChunkName: "DPR" */));
const RateCalendar = React.lazy(() =>
  import("../RateCalendar" /* webpackChunkName: "RateCalendar" */)
);
const HomeContainer = React.lazy(() =>
  import("../HomeContainer" /* webpackChunkName: "HomeContainer" */)
);
const NoMatch = React.lazy(() => import("../NoMatch" /* webpackChunkName: "NoMatch" */));
const SearchProperty = React.lazy(() =>
  import("../SearchProperty" /* webpackChunkName: "SearchProperty" */)
);
// const DemandClientManagement = React.lazy(() =>
//   import("../DemandClientManagement" /* webpackChunkName: "DemandClientManagement" */)
// );
const UsersCompanies = React.lazy(() =>
  import("../UsersCompanies" /* webpackChunkName: "UsersCompanies" */)
);
const PropertyMaster = React.lazy(() =>
  import("../PropertyMaster" /* webpackChunkName: "PropertyMaster" */)
);
const PropertyTaxes = React.lazy(() =>
  import("../PropertyTaxes" /* webpackChunkName: "PropertyTaxes" */)
);
const CacheLiveStatus = React.lazy(() =>
  import("../CacheLiveStatus" /* webpackChunkName: "CacheLiveStatus" */)
);
const CancelReservation = React.lazy(() =>
  import("../CancelReservation" /* webpackChunkName: "CancelReservation" */)
);
const ReservationReport = React.lazy(() =>
  import("../ReservationReport" /* webpackChunkName: "ReservationReport" */)
);
const FailedReservations = React.lazy(() =>
  import("../FailedReservations" /* webpackChunkName: "FailedReservations" */)
);

const Policies = React.lazy(() => import("../Policies" /* webpackChunkName: "Policies" */));
const MetadataNotifications = React.lazy(() =>
  import("../MetadataNotifications" /* webpackChunkName: "Policies" */)
);
const HotelbedsMapping = React.lazy(() =>
  import("../HotelbedsMapping" /* webpackChunkName: "HotelbedsMapping" */)
);
const Rooms = React.lazy(() => import("../Rooms" /* webpackChunkName: "Rooms" */));
const AdvancedARISearch = React.lazy(() =>
  import("../AdvancedARISearch" /* webpackChunkName: "AdvancedARISearch" */)
);
const QuickPriceSearch = React.lazy(() =>
  import("../QuickPriceSearch" /* webpackChunkName: "quickPriceSearch" */)
);
const RateAvailabilityClientsAudit = React.lazy(() =>
  import("../RateAvailabilityClientsAudit" /* webpackChunkName: "RateAvailabilityClientsAudit" */)
);
const RatePlanManagement = React.lazy(() =>
  import("../RatePlanManagement" /* webpackChunkName: "RatePlanManagement" */)
);
// const GlobalRatePlanManagement = React.lazy(() =>
//   import("../GlobalRatePlanManagement" /* webpackChunkName: "GlobalRatePlanManagement" */)
// );
const DayRateCalendar = React.lazy(() =>
  import("../DayRateCalendar" /* webpackChunkName: "DayRateCalendar" */)
);
const Rateplans = React.lazy(() => import("../Rateplans" /* webpackChunkName: "Rateplans" */));
const Hotelbeds = React.lazy(() => import("../Hotelbeds" /* webpackChunkName: "Hotelbeds" */));
const RoomtypesRateplansAudit = React.lazy(() =>
  import("../RoomtypesRateplansAudit" /* webpackChunkName: "RoomtypesRateplansAudit" */)
);
const HotelbedsAudit = React.lazy(() =>
  import("../HotelbedsAudit" /* webpackChunkName: "HotelbedsAudit" */)
);
const MetadataExportNew = React.lazy(() =>
  import("../MetadataExportNew" /* webpackChunkName: "MetadataExportNew" */)
);
const Taxes = React.lazy(() => import("../Taxes" /* webpackChunkName: "Taxes" */));
const IntgARIMonitoring = React.lazy(() =>
  import("../IntgARIMonitoring" /* webpackChunkName: "IntgARIMonitoring" */)
);
const AuditARIDashboard = React.lazy(() =>
  import("../AuditARIDashboard" /* webpackChunkName: "AuditARIDashboard" */)
);
const MetaDataPivot = React.lazy(() =>
  import("../MetaDataPivot" /* webpackChunkName: "MetaDataPivot" */)
);
const MMM = React.lazy(() => import("../MMM" /* webpackChunkName: "MMM" */));
const AddProperty = React.lazy(() =>
  import("../AddProperty" /* webpackChunkName: "AddProperty" */)
);
const ARILookup = React.lazy(() => import("../ARILookup" /* webpackChunkName: "ARILookup" */));
const Invoices = React.lazy(() => import("../Invoices" /* webpackChunkName: "Invoices" */));
const ARIAudit = React.lazy(() => import("../ARIAudit" /* webpackChunkName: "ARIAudit" */));
const RatesInventory = React.lazy(() =>
  import("../RatesInventory" /* webpackChunkName: "RatesInventory" */)
);
const ARIFullPush = React.lazy(() =>
  import("../ARIFullPush" /* webpackChunkName: "ARIFullPush" */)
);
const SYNCDISS = React.lazy(() => import("../SyncDISS" /* webpackChunkName: "SyncDISS" */));
const PushClientsQueueStatus = React.lazy(() =>
  import("../PushClientsQueueStatus" /* webpackChunkName: "PushClientsQueueStatus" */)
);

const ClientManagement = React.lazy(() =>
  import("../ClientManagement" /* webpackChunkName: "clientManagement" */)
);
// const AddClient = React.lazy(() => import("../ClientManagement/AddClient" /* webpackChunkName: "addClient" */));
const StratificationManagement = React.lazy(() =>
  import("../StratificationManagement" /* webpackChunkName: "stratificationManagement" */)
);

const ARIDeliverySummary = React.lazy(() =>
  import("../ARIDeliverySummary" /* webpackChunkName: "ARIDeliverySummary" */)
);

class Home extends PureComponent {
  state = {
    isCollapsedClass: ""
  };
  componentDidMount() {
    this.props.asyncGetInvoicingActionItems();
  }

  toggleIsCollapsed = bool => {
    this.setState({
      isCollapsedClass: bool ? "is-collapsed" : ""
    });
  };
  render() {
    const {
      asyncClientMgmtLogout,
      isLoggedIn,
      property,
      asyncGetProperty,
      location,
      actionableInvoicesCount,
      actionableMetadataCount,
      updateSelectedProperty
    } = this.props;

    if (!isLoggedIn) {
      // return <Redirect to="/" />
    }
    const { pathname, search } = location;
    const { isCollapsedClass } = this.state;
    return (
      <AuthConsumer>
        {({ authenticated, logout }) =>
          !authenticated ? (
            <Redirect to={`/?go2=${pathname}${search}`} />
          ) : (
            <div fallback={<Loader />}>
              <ToastContainer
                position="top-right"
                autoClose={10000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                transition={Slide}
              />
              <div id="loader" className="fadeOut">
                <div className="spinner" />
              </div>
              <div className={isCollapsedClass}>
                <Sidebar
                  property={property}
                  actionableInvoicesCount={actionableInvoicesCount}
                  metadataNotificationsCount={actionableMetadataCount}
                  toggleIsCollapsed={this.toggleIsCollapsed}
                  isCollapsedClass={isCollapsedClass}
                />
                <div className="page-container">
                  <Header
                    isCollapsedClass={isCollapsedClass}
                    logout={() => asyncClientMgmtLogout(logout)}
                    isLoggedIn={isLoggedIn}
                    property={property}
                    toggleIsCollapsed={this.toggleIsCollapsed}
                    updateSelectedProperty={updateSelectedProperty}
                  />
                  <main className="main-content">
                    <Suspense fallback={<Loader />}>
                      <RoutesContainer {...{ property, asyncGetProperty }} />
                    </Suspense>
                  </main>
                  <Footer />
                </div>
              </div>
            </div>
          )
        }
      </AuthConsumer>
    );
  }
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

function logErrorToMyService(err, info) {
  console.log(err, info);
}
export default SessionTimerHOC(Home);
function RoutesContainer({ property, asyncGetProperty }) {
  try {
    return (
      <ErrorBoundary>
        <div id="mainContent" className="p-20">
          <Switch>
            <PrivateRoute
              path="/home"
              perform="propertyManagement"
              exact
              component={HomeContainer}
            />
            {/* <PrivateRoute
              perform="demandClientConfig"
              path="/home/demand-client-management"
              component={DemandClientManagement}
            /> */}
            <PrivateRoute
              path="/home/select-property"
              perform="propertyManagement"
              exact
              component={SearchProperty}
            />
            <Route
              path="/home/property-master"
              render={({ match }) => (
                <PropertyMaster
                  match={match}
                  property={property}
                  asyncGetProperty={asyncGetProperty}
                />
              )}
            />
            <Route path="/home/property-taxes/" component={PropertyTaxes} />
            <PrivateRoute
              path="/home/reservation-report"
              component={ReservationReport}
              perform="reports"
            />
            <PrivateRoute
              path="/home/failed-reservations"
              component={FailedReservations}
              perform="reportsFailed"
            />
            <PrivateRoute path="/home/rooms" component={Rooms} perform="rooms" />
            <PrivateRoute path="/home/policies" component={Policies} perform="policies" />
            <PrivateRoute
              path="/home/hotelbeds-mapping"
              component={HotelbedsMapping}
              perform="hotelbedsMapping"
            />
            <PrivateRoute
              path="/home/cache-live-status"
              component={CacheLiveStatus}
              perform="cacheLiveStatus"
            />
            <PrivateRoute
              path="/home/users"
              component={UsersCompanies}
              perform="usersCompaniesManagement"
            />

            <PrivateRoute
              path="/home/cancel-reservation"
              component={CancelReservation}
              perform="cancelReservation"
            />

            <PrivateRoute
              path="/home/advanced-ari-search"
              component={AdvancedARISearch}
              perform="advancedARISearch"
            />
            <PrivateRoute
              path="/home/quick-price-search"
              component={QuickPriceSearch}
              perform="quickPriceSearch"
            />
            <PrivateRoute
              path="/home/rate-availability-audit-clients"
              component={RateAvailabilityClientsAudit}
              perform="rateAvailabilityAudit"
            />

            <PrivateRoute
              path="/home/day-rate-calendar"
              component={DayRateCalendar}
              perform="dayRateCalendarLookup"
            />

            <PrivateRoute
              path="/home/rate-calendar"
              component={RateCalendar}
              perform="dayRateAvailability"
            />

            <Route path="/home/rateplan-management" component={RatePlanManagement} />
            <PrivateRoute
              path="/home/rate-plans"
              component={Rateplans}
              perform="rateplansListManagement"
            />

            <PrivateRoute
              path="/home/hotelbeds-prop-mapping"
              component={Hotelbeds}
              perform="propertyManagement"
            />
            <PrivateRoute
              path="/home/metadata-management"
              component={HotelbedsAudit}
              perform="propertyManagement"
            />
            <PrivateRoute
              path="/home/metadata-management-new"
              component={MetadataExportNew}
              perform="propertyManagement"
            />
            <PrivateRoute
              path="/home/rooms-rates-audit"
              component={RoomtypesRateplansAudit}
              perform="propertyManagement"
            />
            <PrivateRoute path="/home/dpr" component={DPR} perform="propertyManagement" />
            <PrivateRoute path="/home/taxes" component={Taxes} perform="propertyManagement" />
            <PrivateRoute
              path="/home/intg-ari-monitoring"
              component={IntgARIMonitoring}
              perform="IntgARIMonitoringManagement"
            />
            <PrivateRoute
              path="/home/audit-ari-dashboard"
              component={AuditARIDashboard}
              perform="auditARIDashboard"
            />
            <PrivateRoute path="/home/invoices" component={Invoices} perform="invoiceModule" />
            <PrivateRoute path="/home/mmm" component={MMM} perform="suOnlyControls" />
            <PrivateRoute
              path="/home/metadata-pivot"
              component={MetaDataPivot}
              perform="suOnlyControls"
            />
            <PrivateRoute path="/home/ari-audit" component={ARIAudit} perform="suOnlyControls" />
            <PrivateRoute
              path="/home/rates-inventory"
              component={RatesInventory}
              perform="suOnlyControls"
            />
            <PrivateRoute
              path="/home/ari-full-push"
              component={ARIFullPush}
              perform="suOnlyControls"
            />
            <PrivateRoute path="/home/sync-diss" component={SYNCDISS} perform="suOnlyControls" />
            <PrivateRoute
              path="/home/add-property"
              component={AddProperty}
              perform="suOnlyControls"
            />
            <PrivateRoute path="/home/ari-lookup" component={ARILookup} perform="suOnlyControls" />
            <PrivateRoute
              path="/home/metadata-notifications"
              component={MetadataNotifications}
              perform="suOnlyControls"
            />
            <PrivateRoute
              path="/home/push-clients-queue-status"
              component={PushClientsQueueStatus}
              perform="suOnlyControls"
            />
            <PrivateRoute
              path="/home/clients"
              component={ClientManagement}
              perform="clientManagement"
            />
            <PrivateRoute
              path="/home/groups"
              component={StratificationManagement}
              perform="stratificationManagement"
            />
            <PrivateRoute
              path="/home/ari-delivery-summary"
              component={ARIDeliverySummary}
              perform="suOnlyControls"
            />
            <Route render={() => <NoMatch />} />
          </Switch>
        </div>
      </ErrorBoundary>
    );
  } catch (error) {
    return <div>Error</div>;
  }
}
