import React from "react";
import { Route, Link } from "react-router-dom";
import { newTabLinks } from "../../constants";

export default function customisedLink({ label, to, activeOnlyWhenExact }) {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <div className={match ? "customized-link-nav-active" : ""}>
          {match && <i className="ti-hand-point-right mr-1" />}
          {newTabLinks.includes(label) ? (
            <Link
              className="customized-link-nav"
              to={to}
              onClick={event => {
                event.preventDefault();
                window.open(to, "_blank");
              }}
            >
              {label}
            </Link>
          ) : (
            <Link className="customized-link-nav" to={to}>
              {label}
            </Link>
          )}
        </div>
      )}
    />
  );
}
