import React, { Component } from "react";
import { AuthProvider } from "../authContext";
import SecureSS from "../utils/secure-xs/secureSS";

class Auth extends Component {
  state = {
    authenticated: SecureSS.get("auth") || false,
    user: {
      role: SecureSS.get("role") || "visitor"
    },
    homePage: SecureSS.get("homePage") || "/home"
  };

  initiateLogin = () => {};

  logout = () => {
    this.setState({
      authenticated: false,
      user: {
        role: "visitor"
      }
    });
  };

  handleAuthentication = state => {
    SecureSS.set("role", state.user.role);
    SecureSS.set("auth", true);
    SecureSS.set("homePage", state.homePage);
    this.setState({
      authenticated: true,
      user: {
        role: "user"
      },
      homePage: "/home",
      ...state
    });
  };

  render() {
    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      handleAuthentication: this.handleAuthentication,
      logout: this.logout
    };
    return <AuthProvider value={authProviderValue}>{this.props.children}</AuthProvider>;
  }
}

export default Auth;
