import React from "react";
import "./SearchProperty.scss";
import Autosuggest from "react-autosuggest";
import { Input, Spinner } from "reactstrap";

export default class SearchProperty extends React.Component {
  onChange = (event, { newValue }) => {
    this.props.setSearchPropertyBy(newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (
      value.trim().length > 2 &&
      value.toLowerCase() !== "hotel " &&
      value.toLowerCase() !== "hot" &&
      value.toLowerCase() !== "hote" &&
      value.toLowerCase() !== "hotel" &&
      value !== this.props.value
    ) {
      this.props.asyncSearchProperty(value);
    }
  };

  onSuggestionsClearRequested = () => {
    this.props.updateSearchedProperties([]);
    //this.props.onSuggestionClearRequested({});
  };
  handlePropertySelect = (e, { suggestion }) => {
    this.props.onSuggestionSelected(suggestion);
  };
  render() {
    const { properties, value, isSearchPropertyLoading } = this.props;

    const inputProps = {
      placeholder: "Type in a property name",
      value,
      onChange: this.onChange,
      autoFocus: true
    };

    const getSuggestionValue = v => v.propertyName;
    const renderSuggestion = ({ propertyName, city, state, starRating, propertyCode }, { query }) => {
      var regEx = new RegExp(query, "ig");
      const highlightedContent = propertyName.replace(regEx, `<b className='highlight'>$&</b>`);
      return (
        <span>
          <strong>{city + (state !== null ? `, ${state}` : "") + (starRating !== null ? `, ${starRating} Star` : "")}</strong>
          <br />
          <span dangerouslySetInnerHTML={{ __html: highlightedContent }} />
          {propertyCode && <span>({propertyCode})</span>}
        </span>
      );
    };
    const renderSectionTitle = section => <strong>{section.city + ", " + section.state}</strong>;
    const renderInputComponent = inputProps => (
      /* <FormGroup> */
      <Input {...inputProps} type="search" name="search" id="exampleSearch" placeholder="Type in Property Name" />

      /* </FormGroup> */
    );

    return (
      <div className="prop-auto-complete-container">
        <Autosuggest
          suggestions={properties}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
          onSuggestionSelected={this.handlePropertySelect}
        />
        {isSearchPropertyLoading && <Spinner size="sm" color="primary" className="prop-auto-complete-spinner" />}
      </div>
    );
  }
}
