import { SET_SELECTED_CLIENT } from "../actions/invoice";
import { INITIAL_INVOICE_SELECTED_CLIENT_N_FILTER } from "../constants/invoices";

const initialState = {
  clients: [],
  selectedClientAndFilter: INITIAL_INVOICE_SELECTED_CLIENT_N_FILTER
};

export default function onboarding(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SELECTED_CLIENT:
      return { ...state, ...payload };
    default:
      return { ...state, ...payload };
  }
}
