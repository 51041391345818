import {
  getRequest,
  putRequest,
  postRequest,
  delRequest,
  postFileRequest,
  postDownloadRequest
} from "./axios";
import { isASuperUserLoggedIn, isAPotentialUserLoggedIn } from "../utils/secure-xs/secureSS";

// // TODO: Remove this once metadata API has been implemented
// import metadataJson from "../components/MetadataNotifications/notifications.json";

const HT_ADMIN_OLD = "oldAdminEndpoint/";

export const getActiveProperties = () => getRequest(`meta/property/all`);
export const searchProperty = name => getRequest(`admin/allUsers/properties/search/${name}`);
export const getCompanyCodes = () =>
  getRequest(`${HT_ADMIN_OLD}admin/reports/client/companycode/all`);
export const getCompanyCodeList = () =>
  getRequest(`${HT_ADMIN_OLD}admin/reports/list-companycodes`);

export const getPropertyImages = id => getRequest(`admin/properties/propertyImages/${id}`);
export const updatePropertyImages = (propertyId, payload) =>
  postRequest(`admin/properties/saveImagesSequence/${propertyId}`, payload);
export const getPropertyAmenities = id =>
  getRequest(`admin/properties/searchPropertyAmenities/${id}`);
export const saveProperty = property => postRequest(`admin/properties/saveProperty`, property);

export const getClientOutboundRateXML = payload =>
  postRequest(`clientinvoice/intg_audit_ari`, payload);
export const getAuditForPropertyField = ({ objectId, selectedPropertyField, objectType }) =>
  getRequest(
    `metadata/attributes/audit/trail?objectId=${objectId}&objectType=${objectType}&attribute=${selectedPropertyField}`
  );
export const getProperty = id => getRequest(`admin/properties/${id}`);
export const getPropertyInfo = id => getRequest(`admin/client/properties/${id}/info`);
export const getTaxes = payload => postRequest(`${HT_ADMIN_OLD}admin/properties/getTaxes`, payload);
export const getInboundRateXML = payload => postRequest(`admin/supplier_audit_ari`, payload);
export const getOutboundRateXML = payload => postRequest(`admin/intg_audit_ari`, payload);
export const getInboundResXML = ({
  client_confirmation_code,
  property_id,
  company_code,
  request_type,
  source_id
}) =>
  getRequest(
    `admin/reservation_payload/incoming?client_confirmation_code=${client_confirmation_code}&property_id=${property_id}&company_code=${company_code}&request_type=${request_type}&source_id=${source_id}`
  );
export const getInboundResXMLWithRoomType = ({
  client_confirmation_code,
  property_id,
  company_code,
  request_type,
  room_type_id,
  source_id
}) =>
  getRequest(
    `admin/reservation_payload_with_room_type/incoming?client_confirmation_code=${client_confirmation_code}&property_id=${property_id}&company_code=${company_code}&request_type=${request_type}&source_id=${source_id}&room_type_id=${room_type_id}`
  );
export const getOutboundResXML = ({
  ht_confirmation_code,
  property_id,
  company_code,
  request_type,
  source_id
}) =>
  getRequest(
    `admin/reservation_payload/outgoing?ht_confirmation_code=${ht_confirmation_code}&company_code=${company_code}&request_type=${request_type}`
  );

export const getDailyNightlyRates = htConfirmationCodes =>
  getRequest(`admin/dailyRates?htConfirmationCodes=${htConfirmationCodes}`);
export const getDailyNightlyCancelRates = htConfirmationCodes =>
  getRequest(`admin/getCancelCharges?roomIds=${htConfirmationCodes}`);

export const getRooms = id => getRequest(`${HT_ADMIN_OLD}admin/properties/fetchRoomType/${id}`);
export const getAuditData = otaCode => getRequest(`meta/audits/${otaCode}`);

export const getMetadataAudit = (startDate, endDate) =>
  getRequest(`metadata_audit/get?startDate=${startDate}&endDate=${endDate}`);

export const getRoomAmenities = id => getRequest(`meta/roomtypes/${id}/amenities`);
export const updateRoomtypeAmenities = (id, amenities) =>
  putRequest(`meta/roomtypes/${id}/amenities/update`, amenities);
export const getRoomtypeMasterAmenities = id => getRequest(`meta/roomtypes/${id}/masterAmenities`);
export const addRoomtypeAmenity = (id, room) =>
  postRequest(`meta/roomtypes/${id}/amenities/add`, room);
export const deleteRoomtypeAmenity = roomtypeAmenityId =>
  delRequest(`meta/roomtypes/amenities/del/${roomtypeAmenityId}`);
export const addRoom = (id, room) =>
  postRequest(`${HT_ADMIN_OLD}admin/properties/addRoomType/${id}`, room);

export const addPropertyAmenity = payload =>
  postRequest(`admin/properties/addPropertyAmenity`, payload);
export const updatePropertyAmenities = (propertyId, payload) =>
  postRequest(`admin/properties/saveOrUpdatePropertyAmenities/${propertyId}`, payload);
export const deleteAmenity = id => getRequest(`admin/properties/deleteAmenity/${id}`);
export const getMasterAmenities = id =>
  getRequest(`admin/properties/fetchMasterPropertyAmenities/${id}`);

export const updateTaxes = payload => postRequest(`${HT_ADMIN_OLD}admin/properties/taxes`, payload);
export const deleteTaxes = payload =>
  delRequest(`${HT_ADMIN_OLD}admin/properties/taxes?propertyTaxId=${payload.propertyTaxesId}`);

export const outboundARISearch = reqBody => postRequest(`admin/outbound_ari_search`, reqBody);
export const searchReservationReport = params => {
  let path = `admin/client/searchReservationReport`;
  if (isASuperUserLoggedIn()) {
    path = `admin/searchReservationReport`;
  }
  return postRequest(path, params);
};

export const searchReservationReportByHtConf = conf =>
  getRequest(`admin/searchReservationReport/${conf}`);

export const updateReservation = (params = {}) => postRequest("admin/updateReservation", params);

export const cancelReservationNew = req => putRequest("admin/cancelReservation", req);

export const modifyReservation = payload => postRequest(`admin/modifyReservation`, payload);

export const updateCRSAndPMSId = htConfCode =>
  getRequest(`admin/updateCRSAndPMSId?htConfCode=${htConfCode}`);

export const getFailedOutgoingRequests = htConfCode =>
  getRequest(`admin/getFailedOutgoingRequests?htConfCode=${htConfCode}`);

export const repushPegsFailedRequests = resOutgoingIds =>
  getRequest(`admin/repushPegsFailedRequests?resOutgoingIds=${resOutgoingIds}`);

export const purgeReservation = payload => delRequest(`admin/purge-reservation/${payload}`);

export const modifyCRSConfNumber = payload => postRequest(`admin/modifyCRSConfNumber`, payload);

export const getFailedReservations = (params = {}) =>
  postRequest("admin/failed-reservations/get", params);

export const sendMailForFailedReservation = params =>
  getRequest(`admin/failed-reservations/notify/${params}`);

export const getFailedReservationsPayload = (resIncomingId, isIncoming) =>
  getRequest(
    `admin/failed-reservations/payload?resIncomingId=${resIncomingId}&isIncoming=${isIncoming}`
  );

export const getFailedResDetailsByHtConfCode = htConfCode =>
  getRequest(`admin/getReservationDetailsByHtConfCode?htConfCode=${htConfCode}`);

export const getFailedResDetailsByResIncId = resIncomingId =>
  getRequest(`admin/getFailedResDetailsByResIncId?resIncomingId=${resIncomingId}`);

export const getReservationRequestsByClientConfCode = clientConfCode =>
  getRequest(`admin/getReservationRequests?clientConfCode=${clientConfCode}`);

export const getReservationRequests = conf =>
  new Promise((res, rej) => {
    setTimeout(
      () =>
        res({
          body: [
            {
              id: 1,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-18 02:20:16",
              status: "Success",
              reason: ""
            },
            {
              id: 2,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-18 01:20:16",
              status: "Failed",
              reason: "Zero Inventory"
            },
            {
              id: 3,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-17 23:20:16",
              status: "Failed",
              reason: "Zero Inventory"
            },
            {
              id: 4,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-17 21:20:16",
              status: "Failed",
              reason: "Zero Inventory"
            },
            {
              id: 5,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-17 21:19:16",
              status: "Success",
              reason: ""
            },
            {
              id: 6,
              client: "bnberry",
              requestType: "MODIFY",
              requestDateTime: "2021-06-17 20:50:16",
              status: "Success",
              reason: ""
            },
            {
              id: 7,
              client: "bnberry",
              requestType: "CANCEL",
              requestDateTime: "2021-06-17 19:50:16",
              status: "Failed",
              withinPenaltyPeriod: "false",
              reason: "Zero Inventory"
            },
            {
              id: 8,
              client: "bnberry",
              requestType: "CANCEL",
              requestDateTime: "2021-06-17 18:50:16",
              status: "Failed",
              withinPenaltyPeriod: "true",
              reason: "Zero Inventory"
            },
            {
              id: 9,
              client: "bnberry",
              requestType: "BOOK",
              requestDateTime: "2021-06-17 17:50:16",
              status: "Failed",
              reason: "Zero Inventory"
            }
          ]
        }),
      500
    );
  });

export const repushReservationRequest = request =>
  new Promise((res, rej) => {
    setTimeout(
      () =>
        res({
          body: {
            repushSuccess: "false",
            errors: [
              {
                errorCode: 1129,
                errorMsg: "Rateplan not found or active for client",
                errorDesc:
                  "Room is not active, Stop Sell is in effect for this roomtype. Please contact Hotel Trader Support to refresh ARI., roomtypeId = 5758, roomCode = Test Room Pegs QA7, roomName = DXR, propertyId = 277"
              },
              {
                errorCode: 1118,
                errorMsg:
                  "Stop Sell is in effect for this rateplan. Please contact Hotel Trader Support to refresh ARI.",
                errorDesc:
                  "Rateplan is not active. Stop Sell is in effect for this rateplan. Please contact Hotel Trader Support to refresh ARI., rateplanId = 170478, rateCode = HTPKG, propertyId = 277"
              },
              {
                errorCode: 1102,
                errorMsg: "Invalid margin setup, margin not active for client",
                errorDesc:
                  "Invalid margin setup, margin not active for client, rateplan_id = 170478 (HTPKG), client_id = 144 (Hotelbeds) and property_id = 277 (Row NYC)"
              }
            ]
          }
        }),
      500
    );
  });

export const checkForRepushPenalty = resIncomingId =>
  getRequest(`admin/checkForRepushPenalty?resIncomingId=${resIncomingId}`);

export const repushReservation = params => postRequest(`admin/repushReservation`, params);

export const getFailedReservationsOld = (params = {}) =>
  postRequest("admin/failedReservations", params);

export const intgAuditARI = params =>
  postRequest(`${HT_ADMIN_OLD}admin/reports/intgAuditAri`, params);
export const intgAuditARIHBRes = (confirmationNumber, bookingDate) =>
  getRequest(
    `${HT_ADMIN_OLD}admin/reports/hb/reservation_xml?ht_confirmation_code=${confirmationNumber}&sale_date=${bookingDate}`
  );

export const getAllClients = () => getRequest(`clientsIncludeINTG/all`);
export const getAllClientsExcludeScheduled = () => getRequest(`clientExcludeScheduled/all`);

export const getClientGroupings = () => getRequest(`meta/client/group`);
export const addAClientGroup = params => postRequest(`meta/client/group`, params);
export const updateAClientGroup = params => putRequest(`meta/client/group`, params);
export const deleteAClientGroup = id => delRequest(`meta/client/group/${id}`);

// export const getAllClientsIncludingInactive = () => getRequest(`clients/all`);
/* Client management admin apis start*/
export const getApiConnectionDetails = () =>
  getRequest(`adminClientManagement/getApiConnectionDetails`);
export const getClientById = clientId => getRequest(`adminClientManagement/getClient/${clientId}`);
export const toggleClientActive = clientId =>
  postRequest(`adminClientManagement/toggleClientActive?clientId=${clientId}`);
export const checkForDuplicateBookingUsername = (clientId, bookingUsername) =>
  getRequest(
    `adminClientManagement/checkForDuplicateBookingUsername/${clientId}/${bookingUsername}`
  );
export const addAClient = params => postRequest(`adminClientManagement/add-client`, params);
export const updateClientById = params =>
  putRequest(`adminClientManagement/updateClient/${params.id}`, params);
/* Client management admin apis end*/

/* Client management client apis start*/
export const getClientApiConnectionDetails = () =>
  getRequest(`clientManagement/getApiConnectionDetails`);
export const getClient = clientId => getRequest(`clientManagement/getClient`);
export const updateClient = params => putRequest(`clientManagement/updateClient`, params);
/* Client management client apis end*/

/* Old demand client management apis start*/
export const disableAClient = code => putRequest(`clients/disable/${code}`);
export const getAClientCreds = clientCode => getRequest(`client/credentials/${clientCode}`);
export const getSourceCommission = username =>
  getRequest(`clients/${username}/clientSupplierCommissions/all`);
export const saveNewClientCreds = (params, clientCode) =>
  postRequest(`clients/add-client/${clientCode}`, params);
export const disableClientUser = id => putRequest(`admin/user/disable/${id}`);
export const updateSourceCommission = ({ username, row }) =>
  putRequest(`clients/${username}/clientSupplierCommissions/${row.supplierCode}`, row);
export const addSourceCommission = ({ username, row }) =>
  postRequest(`clients/${username}/clientSupplierCommissions`, row);
export const getAllSuppliers = () => getRequest(`suppliers/all`);
export const getDirectCommissions = user => getRequest(`clients/${user}/rateplans/all`);
export const addOrUpdateRateplans = (user, propId, plan) =>
  postRequest(`clients/${user}/property/${propId}/rateplans`, plan);
export const getBlacklistedProperties = user => getRequest(`clients/${user}/blacklistProperty`);
export const blacklistProperty = ({ user, id }) =>
  postRequest(`clients/${user}/blacklistProperty/${id}`);
export const reListProperty = ({ user, id }) =>
  putRequest(`clients/${user}/whitelistProperty/${id}`);
/* Old demand client management apis end*/

export const getUserProfile = () => getRequest(`profile`);
export const getCacheRates = params => getRequest(`clients/search/hotels/cache/rates?${params}`);
export const searchCity = city => getRequest(`clients/search/hotels/city-suggestions/${city}`);

//Should be deleted the below endpoints?
export const getAllPolicies = propId =>
  getRequest(`${HT_ADMIN_OLD}admin/property/${propId}/policies/all`);
export const getAPolicy = (propId, user) =>
  getRequest(`${HT_ADMIN_OLD}admin/property/${propId}/policies/${user}`);
export const addAPolicy = params => postRequest(`${HT_ADMIN_OLD}admin/policies`, params);
export const updateAPolicy = params =>
  putRequest(`${HT_ADMIN_OLD}admin/policies/${params.policyCode}`, params);
export const deleteAPolicy = params =>
  delRequest(`${HT_ADMIN_OLD}admin/policies/${params.policyCode}`, params);

//Users Companies Management apis
export const getAllUsers = () => getRequest(`users/list`);
export const checkForDuplicateUsers = (userId, username) =>
  getRequest(`users/checkForDuplicateUsers/${userId}/${username}`);
export const getUserDetails = username => getRequest(`admin/get/user?username=${username}`);
export const addUser = params => postRequest(`users/user`, params);
export const updateUser = params => postRequest(`${HT_ADMIN_OLD}admin/edit/user`, params);
export const deleteUser = params => postRequest("users/user/delete", params);

//cancel reservation apis
export const getReservation = params =>
  postRequest(`${HT_ADMIN_OLD}superuser/sucontrols/cancelReservationInfo`, params);
export const cancelReservation = htConfCode =>
  getRequest(`${HT_ADMIN_OLD}superuser/sucontrols/suCancelReservation/${htConfCode}`);

//day rate availability lookup apis
export const getDayRateProperties = () =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/getPropertyIds`);
export const getRatePlanAndRoomIds = propId =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/getRatePlanAndRoomIds?propertyId=${propId}`);
export const checkDayRate = queryParams =>
  getRequest(`${HT_ADMIN_OLD}admin/checkdayrate/check${queryParams}`);
export const getDayRatesYearly = requestPayload => {
  if (isASuperUserLoggedIn()) {
    return postRequest(`rates/dayrates`, requestPayload);
  } else if (isAPotentialUserLoggedIn()) {
    return postRequest(`rates/potential-user/dayrates`, requestPayload);
  } else {
    return postRequest(`rates/client/dayrates`, requestPayload);
  }
};

export const getSearchApiRates = requestPayload => {
  let path = "rates/client/searchApi/rates";
  if (isASuperUserLoggedIn()) {
    path = "rates/searchApi/rates";
  }
  return postRequest(path, requestPayload);
};

export const getDayRatesYearly2csv = requestPayload =>
  postRequest(`rates/dayrates2csv`, requestPayload);
export const bookReservationForDayrate = req => postRequest("rates/make-reservation", req);
export const getAllRatePlansByPid = propId => getRequest(`rateplans/${propId}`);
export const getRatePlansByPid = propId => {
  if (isASuperUserLoggedIn() || isAPotentialUserLoggedIn()) {
    return getRequest(`meta/rateplans/${propId}`);
  } else {
    return getRequest(`meta/client/rateplans/${propId}`);
  }
};
export const getRoomTypesByRoomTypeId = propId => getRequest(`meta/roomtypes/roomtype/${propId}`);
export const getRoomTypesByPid = propId => getRequest(`meta/roomtypes/${propId}/activerooms`);
export const getAllRoomTypesByPid = propId => getRequest(`meta/roomtypes/${propId}/allrooms`);
export const getDayRatesYearlyDemo = queryParams =>
  getRequest(`rates/dayrates/demo?${queryParams}`);
export const saveRoomType = room => postRequest(`meta/roomtypes/save`, room);

//rateplans
export const getRateplan = rateplanId => getRequest(`rateplans/rateplan/${rateplanId}`);
export const saveRateplanNew = plan => postRequest(`rateplans/rateplan/saveRatePlan`, plan);

export const saveRoomTypeNew = room => postRequest(`meta/roomtypes/roomtype/saveRoomtype`, room);
export const saveRateplan = plan => postRequest(`meta/rateplan/`, plan);
export const getPolicies = propId => getRequest(`meta/policies/${propId}`);
export const createDprRateplans = (propId, noOfRefDprRps, noOfNonRefDprRps) =>
  postRequest(`meta/rateplan/createDprRateplans?propertyId=${propId}
    &noOfRefDprRps=${noOfRefDprRps}&noOfNonRefDprRps=${noOfNonRefDprRps}`);

export const getRateplanClientMappings = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getReztripRatePlansByPlatform/${propertyId}`);

export const saveRateplanManagementData = (propertyId, rateplanManagementData) =>
  postRequest(`${HT_ADMIN_OLD}saveRatePlansByPlatform/${propertyId}`, rateplanManagementData);

export const getPropertyRateplans = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getRatePlansByProperty/${propertyId}`);

export const hotelbedsPropertyUpload = propertyId =>
  getRequest(`${HT_ADMIN_OLD}getRatePlansByProperty/${propertyId}`);

export const getDistinctActiveRateCodes = () => getRequest(`rateplans/ratecodes`);

export const getAllActiveInterfaces = () => getRequest(`interfaces/all/active`);

export const getClientToRateplanMappingsByRateCode = rateCode =>
  getRequest(`rateplans/getClientMappingsByRateCode/${rateCode}`);

// delete the below one once the above is functional
export const getClientToRateplanMappingsByRateCode2 = rateCode =>
  getRequest(`${HT_ADMIN_OLD}getClientToRateplanMappingsByRateCode/${rateCode}`);

export const saveGlobalRateplanManagementData = (rateCode, rateplanManagementData) =>
  postRequest(`rateplans/saveGlobalRateplanManagementData/${rateCode}`, rateplanManagementData);

export const disableRateplan = rateplanId => postRequest(`rateplans/disableRateplan/${rateplanId}`);
export const duplicateRatePlan = (propId, rateplanId, roomTypes) =>
  postRequest(`meta/rateplan/duplicate/${propId}/${rateplanId}`, roomTypes);

//policy
export const getPolicy = policyId => getRequest(`meta/policies/policy/${policyId}`);
export const policyAddOrUpdate = policy => postRequest(`meta/policies/policy/savePolicy`, policy);
export const isPolicyTiedToRateplan = policyId =>
  getRequest(`meta/isPolicyTiedToRateplan/${policyId}`);
export const deletePolicy = policyId => delRequest(`meta/policy/${policyId}`);

export const getMetadataNotifications = (propertyId, hours) =>
  getRequest(`metadata/notifications/?propertyId=${propertyId}&hours=${hours}`);
export const snoozeNotification = reqBody => postRequest(`metadata/notification/snooze`, reqBody);
export const dismissNotification = notificationId =>
  postRequest(`metadata/notifications/${notificationId}/dismiss`);
export const saveCrsValueOrOverrideAttirbute = reqBody =>
  postRequest(`metadata/attributes/update`, reqBody);
export const getAttributeAuditTrail = (objectId, objectType, attribute) =>
  getRequest(
    `metadata/attributes/audit/trail?objectId=${objectId}&objectType=${objectType}&attribute=${attribute}`
  );

//Remove this when the platform tables are deleted
export const getAllPlatforms = () => getRequest(`meta/platforms/all`);
export const getAllClientsData = () => getRequest(`meta/clients/all`);
export const getAllActiveClientsData = () => getRequest(`meta/clients/allActive`);
export const getAllIntgClients = () => getRequest(`meta/pushClients/all`);

export const exportPropertyTemplate = (propCode, exportPayload, includeHbMapping) =>
  postRequest(
    `admin/property/template/export/${propCode}?includeHbMapping=${includeHbMapping}`,
    exportPayload
  );
export const exportPropertyTemplateNew = (propCode, exportPayload, includeHbMapping) =>
  postRequest(
    `admin/property/template/exportNew/${propCode}?includeHbMapping=${includeHbMapping}`,
    exportPayload
  );
export const downloadFile = url => getRequest(`${url}`);
export const comparePropertyTemplate = propCode =>
  getRequest(`admin/property/template/comparePropertyMapping/${propCode}`);
export const compareAllPropertyTemplate = () =>
  getRequest(`admin/property/template/compare-property-mapping/all`);

export const downloadExportProperty = filePath => getRequest(filePath);
export const importPropertyTemplate = (file, readOnly) =>
  postRequest(`admin/property/template/importFile?readOnly=${readOnly}`, file);
export const getHBMapping = propId => getRequest(`hbmapping/${propId}`);
export const auditReztripRoomtypes = (propId, file) =>
  postRequest(`admin/metadata/import/audit/reztrip?property_id=${propId}`, file).set(
    "Content-Type",
    "multipart/form-data"
  );
// export const auditReztripRoomtypes = (propId, file) =>
//     postRequest(`admin/metadata/import/audit/reztrip?property_id=${propId}&reztrip_excel_sheet=${file}`);
export const deleteRoomtypes = roomtypes =>
  delRequest(`admin/metadata/import/reztrip/roomtypes/delete_all`, roomtypes);
export const saveRoomtypes = (propId, roomtypes) =>
  postRequest(`admin/metadata/import/reztrip/roomtypes/add_all?property_id=${propId}`, roomtypes);

export const updateRoomtypes = roomtypes =>
  putRequest(`admin/metadata/import/reztrip/roomtypes/update_all`, roomtypes);

//DPR

export const fetchDPR = () => getRequest("dpr/all");
export const deleteDPRRateplan = dprRateplanId =>
  delRequest(`dpr/deleteDPRRateplan/${dprRateplanId}`);
export const checkForDprExistence = (dprRateplanId, dprRateplanShouldExist) =>
  getRequest(`dpr/checkForDprExistence/${dprRateplanId}/${dprRateplanShouldExist}`);
export const fetchDPRHistory = dprHistoryId => getRequest(`dpr/history/${dprHistoryId}`);
export const enableDPR = rateplanId => getRequest(`rateplans/enableDpr/${rateplanId}`);
export const disableDPR = rateplanId => getRequest(`rateplans/disableDPR/${rateplanId}`);
export const associateDprRateplan = dprRp => postRequest(`dpr/associateDprRateplan`, dprRp);
export const associateDpr = dprRp => postRequest(`dpr/associateDpr`, dprRp);
export const getAllPropertiesActiveRateplansIdCodes = () =>
  getRequest("rateplans/getAllPropertiesActiveRateplansIdCodes");
export const getAllPropertiesActiveRoomsIdCodes = () =>
  getRequest("meta/roomtypes/getAllPropertiesActiveRoomsIdCodes");
export const getAllPropertiesActiveRateplansRoomtypesIdCodes = () =>
  getRequest(`meta/getAllPropertiesActiveRateplansRoomtypesIdCodes`);

export const getTaxesByTaxId = propId => getRequest(`admin/tax/${propId}`);
export const savePropertyTaxNew = tax => postRequest(`admin/tax/saveTax`, tax);

export const getTaxesByPid = propId => getRequest(`admin/${propId}/taxes`);
export const saveOrUpdatePropertyTax = tax => postRequest(`admin/taxes/saveOrUpdate`, tax);
export const deletePropertyTax = propTaxId => delRequest(`admin/taxes/delete/${propTaxId}`);

export const getAllInterfaces = () => getRequest(`getAllInterfaces/`);
export const getClientRecords = (client, status) =>
  getRequest(`getClientRecords/${client}?status=${status}`);

export const getReportDashboard = () => getRequest(`admin/dashboard/report`);
export const getAriReportDashboard = () => getRequest(`admin/dashboard/report/ari`);
export const getResTotals = filter =>
  getRequest(`admin/dashboard/report/getGrossTotalsByFilter?filter=${filter}`);

export const getAriFullPushFilters = () => getRequest(`ari/getAriFullPushFilters`);
export const getAriFullPullFilters = () => getRequest(`ari/getAriFullPullFilters`);
export const ariFullPush = reqBody => postRequest(`ari/fullPush`, reqBody);
export const ariFullPushDiss = reqBody => postRequest(`ari/fullPushDISS`, reqBody);

export const metadataFullPushDiss = reqBody => postRequest(`meta/syncAllMetadataToDISS`, reqBody);
export const marginsFullPushDiss = reqBody => postRequest(`margins/syncAllMarginsToDISS`, reqBody);

export const getNextSyncMarginToDISSResults = (reqBody, pageNumber) =>
  postRequest(`margins/getNextSyncMarginToDISSResults?pageNumber=${pageNumber}`, reqBody);

export const sendDiscrepancyEmail = htConfCode =>
  getRequest(`admin/sendDiscrepancyEmail?htConfCode=${htConfCode}`);

//Audit ARI Dahsboard APIs
export const getARIResults = requestPayload => postRequest("getARIResults", requestPayload);
export const getErrorARIDetails = requestPayload =>
  postRequest("getErrorARIDetails", requestPayload);

// su only API
export const getRatesInventory = params => postRequest("meta/rates-inventory", params);
export const getCategorisedRateplans = () => getRequest("rateplans/getCategorisedRateplans");
export const getAriDeliveryInboundSummary = reqBody =>
  postRequest(`ari/audit/summaryInbound`, reqBody);
export const getAriDeliveryOutboundSummary = reqBody =>
  postRequest(`ari/audit/summaryOutbound`, reqBody);
export const getActivePropertyRateplanRoomtypes = () =>
  getRequest("meta/active-properties-rateplans-roomtypes");
export const getInboundSummary = params => postRequest("ari/audit/summaryScrollInbound", params);
export const getOutboundSummary = params => postRequest("ari/audit/summaryScrolOutbound", params);
export const getOutboundSummaryExpand = (type, params) =>
  postRequest(`ari/audit/expand?type=${type}`, params);

export const fetchInboundXML = params => postRequest("ari/audit/fetchInboundXML", params);
export const fetchOutboundHtngXML = params => postRequest("ari/audit/fetchOutboundHtngXML", params);
export const fetchOutboundHBXML = params => postRequest("ari/audit/fetchOutboundHBXML", params);

export const fetchOutboundAriRawXml = params => postRequest("admin/outbound_ari_raw", params);
export const fetchInboundAriRawXml = params => postRequest("admin/inbound_ari_raw", params);
export const getOutboundAriAudit = htConfCode =>
  getRequest(`admin/outbound_ari_audit?htConfirmationCode=${htConfCode}`);
export const getInboundAriAudit = htConfCode =>
  getRequest(`admin/inbound_ari_audit?htConfirmationCode=${htConfCode}`);

//client invoicinig
export const getClientsAndNotifications = () => getRequest(`invoice/clients-notifications`);
export const getInvoiceNotifications = isClient =>
  isClient ? getRequest(`clientinvoice/notification`) : getRequest(`invoice/notification`);
export const getPaymentDetails = ({ isClient, clientId }) =>
  isClient
    ? getRequest(`clientinvoice/payment-details`)
    : getRequest(`invoice/payment-details?clientId=${clientId || ""}`);
export const dismissInvoiceNotifications = ({ isClient, notificationId }) =>
  isClient
    ? postRequest(`clientinvoice/notification/${notificationId}/dismiss`)
    : postRequest(`invoice/notification/${notificationId}/dismiss`);
export const getClientInvoices = ({ isClient, clientId, filter }) =>
  isClient
    ? getRequest(`clientinvoice/getInvoices?filter=${filter}`)
    : getRequest(`invoice/client/${clientId}?filter=${filter}`);
export const getPaymentsForClientAndInvoice = ({ clientId, invoiceId }) =>
  clientId
    ? getRequest(`invoice/payments/client/${clientId}/invoice/${invoiceId}`)
    : getRequest("clientinvoice/payments/invoice/" + invoiceId);

export const getClientInvoiceById = ({ clientId, invoiceIds, filter, isClient }) =>
  isClient
    ? postRequest(`clientinvoice/getResByInvoices`, { invoiceIds, filter })
    : postRequest(`invoice/getResByInvoices`, { invoiceIds, filter, clientId });
export const getInvoiceSummary = ({ clientId, invoiceIds, isClient, filter }) =>
  isClient
    ? postRequest(`clientinvoice/getInvoicesSummary`, { invoiceIds, filter })
    : postRequest(`invoice/getInvoicesSummary`, { invoiceIds, clientId, filter });
export const fetchPaymentsForReservation = ({ clientId, reservationInvoiceId, isClient }) =>
  isClient
    ? getRequest(`clientinvoice/payments-res/${reservationInvoiceId}`)
    : getRequest(`invoice/payments-client-res/${clientId}/${reservationInvoiceId}`);
export const getAuditLogForInvoice = ({ clientId, reservationInvoiceId, isClient }) =>
  isClient
    ? getRequest(`clientinvoice/client-audit-trail?resInvoiceId=${reservationInvoiceId}`)
    : getRequest(`invoice/invoice-audit-trail?resInvoiceId=${reservationInvoiceId}`);

export const getCreditUsageInfo = ({ clientId, htConfirmationCode, isClient }) =>
  isClient
    ? getRequest(`clientinvoice/getCreditUsageInfo?htConfirmationCode=${htConfirmationCode}`)
    : getRequest(`invoice/${clientId}/getCreditUsageInfo?htConfirmationCode=${htConfirmationCode}`);

export const getAccountSummary = clientId =>
  clientId
    ? getRequest(`invoice/clientDueSummary/${clientId}`)
    : getRequest(`clientinvoice/getDueSummary`);

export const getAllClientsAgingSummary = () => getRequest("invoice/allClientsAgingSummary/");
export const getClientSchedules = clientId => getRequest("invoice/client-schedules/" + clientId);
export const getInvoicingActionItems = () => getRequest("meta/action-items");
export const saveAdminInvoiceChanges = reqBody =>
  postRequest("invoice/admin-save-changes", reqBody);
export const addInvoiceShedulingDetails = reqBody =>
  postRequest(`invoice/invoice-details/add`, reqBody);
export const editInvoiceShedulingDetails = reqBody =>
  postRequest(`invoice/invoice-details/${reqBody.id}/edit`, reqBody);
export const getInvoiceShedulingDetails = () =>
  getRequest("invoice/invoice-scheduling-details/all");

export const invoiceDoNotAllocateFunds = reqBody =>
  postRequest("invoice/save-payment-not-allocate", reqBody);
export const invoiceAllocateFunds = reqBody => postRequest("invoice/allocate-folios", reqBody);
export const saveAndSubmitLaterInvoice = reqBody =>
  postRequest("clientinvoice/client-save", reqBody);
export const submitAndPayInvoice = reqBody => postRequest("clientinvoice/client-save-pay", reqBody);
export const admineApproveDeclineAction = reqBody =>
  postRequest("invoice/admin-approve-decline", reqBody);
export const getClientCreditTotals = after =>
  getRequest("clientinvoice/getClientCreditTotals?after=" + after);
export const getClientCredits = clientId => getRequest("invoice/credits/client/" + clientId);
export const getClientCreditsTotals = clientId =>
  getRequest("invoice/credits/client/" + clientId + "/total");
export const getCreditsTotals = clientId => getRequest("clientinvoice/credits/total");
export const getCreditsForType = ({ isClient, selectedCard, clientId }) =>
  postRequest(
    isClient
      ? "clientinvoice/credits?filter=" + selectedCard
      : `invoice/credits/client/${clientId}/getByFilter?filter=${selectedCard}`
  );

export const searchCredits = ({ creditsRequest, isClient, clientId }) =>
  postRequest(
    isClient ? "clientinvoice/credits/client/creditlist/" : "invoice/credits/client/creditlist/",
    creditsRequest
  );

export const getAutoAllocatedReservation = () =>
  getRequest("clientinvoice/get-auto-allocated-reservation");
export const acceptAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-accept");
export const allocateForSelectedReservations = payload =>
  postRequest("clientinvoice/client-allocation-finalize", payload);
export const rejectAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-decline");

export const downloadInvoice = (invoiceIds, filter) =>
  postFileRequest("clientinvoice/download", { invoiceIds, filter });

export const downloadInvoiceByClient = (invoiceIds, filter, clientId) =>
  postFileRequest("invoice/download", { invoiceIds, filter, clientId });

export const uploadInvoice = (file, invoiceId) =>
  postRequest("clientinvoice/uploadInvoice?invoiceId=" + invoiceId, file);
export const fetchAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}`, payload);
export const finalizeAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}/finalize`, payload);

export const uploadSupplierInvoice = (file, supplierInvoiceRequest) =>
  postRequest(
    "invoice/uploadSupplierInvoice?startingRow=" +
      supplierInvoiceRequest.startingRow +
      "&crsColumn=" +
      supplierInvoiceRequest.crsColumn +
      "&totalAmountColumn=" +
      supplierInvoiceRequest.totalAmountColumn +
      "&invoiceDateColumn=" +
      supplierInvoiceRequest.invoiceDateColumn,
    file
  );

export const downloadSupplierInvoice = fileName =>
  postDownloadRequest("invoice/downloadSupplierInvoice?fileName=" + fileName);

//Margin Management Module APIs
export const getMarginFilters = () => getRequest(`margins/getMarginFilters`);
export const getRateCodesByPids = pIds => getRequest(`meta/getPropertyRateplans/${pIds}`);
export const fetchConflictingOverrides = reqBody =>
  postRequest(`margins/fetchConflictingOverrides`, reqBody);
export const saveMargins = (requestType, reqBody) =>
  postRequest(`margins/saveMargins?requestType=${requestType}`, reqBody);
export const fetchMarginOverrides = reqBody => postRequest(`margins/fetchMarginOverrides`, reqBody);
export const fetchMultipleMarginOverrides = reqBody =>
  postRequest(`margins/fetchMultipleMarginOverrides`, reqBody);
export const deleteOverrides = reqBody => postRequest(`margins/deleteOverrides`, reqBody);
export const saveMarginOverrides = reqBody => postRequest(`margins/saveMarginOverrides`, reqBody);
export const saveMarginsAndOverrides = reqBody =>
  postRequest(`margins/saveMarginsAndOverrides`, reqBody);
export const saveMarginsAndOverridesNew = reqBody =>
  postRequest(`margins/saveMarginsAndOverridesNew`, reqBody);
export const fetchClientMarginGrid = reqBody =>
  postRequest(`margins/fetchClientMarginGrid`, reqBody);
export const fetchPropertyMarginGrid = reqBody =>
  postRequest(`margins/fetchPropertyMarginGrid`, reqBody);
export const fetchRateplanMarginGrid = reqBody =>
  postRequest(`margins/fetchRateplanMarginGrid`, reqBody);
export const getClientPropertyRateplanOverrides = reqBody =>
  postRequest(`margins/getClientPropertyRateplanOverrides`, reqBody);
export const editMarginOverride = reqBody => postRequest(`margins/editMarginOverride`, reqBody);
//End of Margin Management Module APIs

export const getPushClientsQueueStatus = () => getRequest(`sqs/getQueueMessagesDetails`);

//Add New Pegs Property apis
export const searchAllProperties = name => getRequest(`admin/properties/all/search/${name}`);
export const getRateplanTemplate = () => getRequest(`metadata/getRateplantemplate`);
export const getLevelWiseMarginsByBrand = brand =>
  getRequest(`metadata/getLevelWiseMarginsByBrand?brand=${brand}`);
export const createPropertyTemplate = reqBody =>
  postRequest(`metadata/createPropertyTemplate`, reqBody);
//End of Add New Pegs Property apis
